import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { ContactUsAccordian } from '../components/contact-us/ContactUsAccordian'

export const Faqs = () => {
    return (
        <div>
            <div className='faq-bg-img'>
                <Container maxWidth='xl'>
                    <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight={400}>
                        {/* <Animation animationIn='wobble' isVisible animationInDelay={500}> */}
                        <Typography variant='h3' className='ff-mi' color={'white'} textAlign={'center'}>FAQ'S</Typography>
                        {/* </Animation> */}
                    </Grid>
                </Container>
            </div>
            <Container maxWidth='lg'>
                <Grid container display={'flex'} py={7}>
                    <Grid item sm={12} md={6}>
                        <Typography className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>FAQ</Typography>
                        <Typography className='ff-mi' variant='h4' marginY={2}>Frequently Asked Questions</Typography>
                        {/* <img src="assets/element-blur-2 1.webp" alt="" className='blur-section-6' /> */}
                        <ContactUsAccordian />
                    </Grid>
                    <Grid container item mt={10} sm={12} md={6} display={'flex'} justifyContent={'center'}>
                        <Grid container item sm={12} md={9} display={'flex'} justifyContent={'center'}>
                            <Grid padding={1} item xs={9}>
                                <div className='d-flex justify-content-end'>
                                    <img src="assets/element-ellipse-1-300x300_1.webp" alt="" style={{ height: 100, marginBottom: -85, marginRight: -45 }} />
                                </div>
                                <div className='d-flex justify-content-start'>
                                    <div className='rounded-circle p-2' style={{ width: 50, height: 50, backgroundColor: '#F6CC36', marginBottom: -20, zIndex: 1500 }}>
                                        <img src="assets/icons/Group_(4).svg" alt="" height={'100%'} />
                                    </div>
                                </div>
                                <img src="assets/Frame_21_(2).webp" width={'100%'} alt="" />
                                <div className='d-flex justify-content-end'>
                                    <div className='rounded-circle py-2 ps-1' style={{ width: 50, height: 50, backgroundColor: '#F92E5E', marginTop: -20, zIndex: 999 }}>
                                        <img src="assets/icons/Vector_(4).svg" alt="" height={'100%'} style={{ marginLeft: '.09em' }} />
                                    </div>
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}
