import { SESv2Client, SendEmailCommand } from "@aws-sdk/client-sesv2";
import { toast } from "react-toastify";
const client = new SESv2Client({ region: "us-east-2", credentials: { accessKeyId: 'AKIARVGZDK3OYJGNPUGP', secretAccessKey: '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' } });
const options = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}
export const sendSESMail = async (subject, template) => {
    const command = new SendEmailCommand({
        Destination: {
            ToAddresses: ['info@agrivimaan.com'],
        }, Content: {
            Simple: {
                Subject: { Charset: 'UTF-8', Data: subject },
                Body: {
                    Charset: 'UTF-8',
                    Html: {
                        Charset: 'UTF-8',
                        Data: template
                    }
                }
            }
        },

        FromEmailAddress: 'info@agrivimaan.com',
    });
    try {
        await client.send(command);
        toast.success('Data send/submit successfully.', options)
        return
    } catch (error) {
        toast.error('Somethig went worng ! Please try after sometimes.', options)
        return
    }
}