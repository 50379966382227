import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export const AboutUsArialProject = () => {
    return (
        <div>
            <Grid container display={'flex'} alignItems={'center'} mt={10}>
                <Grid item xs={12} md={4} >
                    <Typography className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>AERIAL PROJECT</Typography>
                    <Grid >
                        <Typography className='ff-mi' variant='h4' textAlign={'start'} marginY={2}>Some Beautiful
                            Projects</Typography>
                        {/* <div style={{ marginTop: -120 }} className='d-flex justify-content-start'><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant='h6' color={'#656565'}>Impressive portfolio. Cutting-edge solutions. Real-world impact. Trusted leaders. Customized approach. Unmatched expertise. Success stories. Future-focused innovation.</Typography>
                </Grid>
            </Grid>
            <Grid container mt={2} pb={5}>
                <Grid item xs={12} sm={6} md={4} paddingX={2} marginY={2}>
                    <img src="assets/Frame_26.webp" alt="" width={'100%'} style={{ marginTop: 0, marginBottom: 40 }} />
                    <Typography variant='h6' className='ff-mi' fontWeight={'bolder'}>01. AERIAL DRONE</Typography>
                    <Typography color={'#656565'} className='ff-mo ts-14'>Unleash the power of aerial drones, capture
                        stunning aerial views, gather valuable data,
                        and revolutionize industries.</Typography>
                    <Link className='fw-bold ts-12'>LEARN MORE</Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4} paddingX={2} marginY={2}>
                    <img src="assets/Frame_27.webp" alt="" width={'100%'} style={{ marginTop: 40, marginBottom: 0 }} />
                    <Typography variant='h6' className='ff-mi' fontWeight={'bolder'} marginTop={'40px'}>02. 4K ADVERTISE</Typography>
                    <Typography color={'#656565'} className='ff-mo ts-14'>Elevate your advertising with our 4K drone.
                        Capture stunning aerial footage for impactful
                        campaigns. Stand out with high-quality visuals.</Typography>
                    <Link className='fw-bold ts-12'>LEARN MORE</Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4} paddingX={2} marginY={2}>
                    <img src="assets/Frame_28.webp" alt="" width={'100%'} style={{ marginTop: 0, marginBottom: 40 }} />
                    <Typography variant='h6' className='ff-mi' fontWeight={'bolder'}>03. WORK TO GO</Typography>
                    <Typography color={'#656565'} className='ff-mo ts-14'>Get work done efficiently with our reliable
                        drone solutions. From inspections to mapping,
                        our drones are equipped to tackle various tasks.</Typography>
                    <Link className='fw-bold ts-12'>LEARN MORE</Link>
                </Grid>
            </Grid>
        </div>
    )
}
