import { Grid, Typography } from '@mui/material'
// import { Animation } from 'react-animate-style'
import React from 'react'

export const AboutUsWhatWeDo = () => {
    return (
        <div>
            <Grid paddingY={10}>
                <Typography textAlign={'center'} className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>WHAT WE DO</Typography>
                <Grid container display={'flex'} justifyContent={'center'}>
                    <Grid item xs={12} sm={9} md={6}>
                        <Typography textAlign={'center'} className='ff-mi' variant='h4' marginY={2}>Provide Drone and Aerial
                            Photography Service</Typography>
                        {/* <div className='d-flex justify-content-start' style={{ marginTop: -140 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}
                    </Grid>
                </Grid>
                <Grid container display={'flex'} justifyContent={'space-around'} mt={5}>
                    <Grid item xs={12} sm={6} md={4} marginY={3}>
                        {/* <Animation className='h-100' animationIn="fadeInLeftBig" animationOut='backInDown' isVisible={true} animationInDelay={1000} animationInDuration={1000}> */}
                        <div className='card bg-white p-2 m-3 rounded-4 h-100 shadow'>
                            <div className='d-flex justify-content-between' style={{ marginTop: -50 }}>
                                <div className='d-flex justify-content-center align-items-center bg-primary rounded-circle ms-3' style={{ height: 80, width: 80 }}>
                                    <img src="assets/icons/Group_(5).svg" alt="" />
                                </div>
                            </div>
                            <div className='card-body'>
                                <Typography variant='h5' className='ff-mi' fontWeight={'bold'}>Precision Crop Monitoring</Typography>
                                <Typography color={'#656565'} className='ff-mo'>Precise imaging. Informed decisions. Efficient farming.</Typography>
                            </div>
                        </div>
                        {/* </Animation> */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} marginY={3} >
                        {/* <Animation className='h-100' animationIn="fadeInLeftBig" animationOut='backInDown' isVisible={true} animationInDelay={500} animationInDuration={1000}> */}
                        <div className='card bg-white p-2 m-3 rounded-4 h-100 shadow'>
                            <div className='d-flex justify-content-between' style={{ marginTop: -50 }}>
                                <div className='d-flex justify-content-center align-items-center bg-primary rounded-circle ms-3' style={{ height: 80, width: 80 }}>
                                    <img src="assets/icons/Group_8.svg" alt="" />
                                </div>
                            </div>
                            <div className='card-body'>
                                <Typography variant='h5' className='ff-mi' fontWeight={'bold'}>Efficient Data Collection</Typography>
                                <Typography color={'#656565'} className='ff-mo'>Enhanced decision-making. Streamlined processes for timely accuracy.</Typography>
                            </div>
                        </div>
                        {/* </Animation> */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} marginY={3}>
                        {/* <Animation className='h-100' animationIn="fadeInLeftBig" animationOut='backInDown' isVisible={true} animationInDuration={1000}> */}
                        <div className='card bg-white p-2 m-3 rounded-4 h-100 shadow'>
                            <div className='d-flex justify-content-between' style={{ marginTop: -50 }}>
                                <div className='d-flex justify-content-center align-items-center bg-primary rounded-circle ms-3' style={{ height: 80, width: 80 }}>
                                    <img src="assets/icons/Group_9.svg" alt="" />
                                </div>
                            </div>
                            <div className='card-body'>
                                <Typography variant='h5' className='ff-mi' fontWeight={'bold'}>Actionable Insights</Typography>
                                <Typography color={'#656565'} className='ff-mo'>Actionable insights. Informed decisions. Optimized farming.</Typography>
                            </div>
                        </div>
                        {/* </Animation> */}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
