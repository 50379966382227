import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Home } from './pages/Home'
import { AboutUs } from './pages/AboutUs'
import { Product } from './pages/Product'
import DroneExperties from './pages/DroneExperties'
import { ContactUs } from './pages/ContactUs'
import { Product2 } from './pages/Product2'
import { PriceDrone } from './pages/PriceDrone'
import { PriceSpray } from './pages/PriceSpray'
import { useLayoutEffect } from 'react'
import { Gallery } from './pages/Gallery'
import { Faqs } from './pages/Faqs'

export const Routings = () => {
    const location = useLocation();
    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <Routes>
            <Route>
                <Route exact path="/" element={<Home />}></Route>
                <Route exact path="/gallery" element={<Gallery />}></Route>
                <Route exact path="/aboutus" element={<AboutUs />}></Route>
                <Route exact path="/product" element={<Product />}></Route>
                <Route exact path="/product-hexa" element={<Product2 />}></Route>
                <Route exact path="/agriculture" element={<DroneExperties />}></Route>
                <Route exact path="/price" element={<PriceDrone />}></Route>
                {/* <Route exact path="/price-spray" element={<PriceSpray />}></Route> */}
                <Route exact path="/contact" element={<ContactUs />}></Route>
                <Route exact path="/contact-faq" element={<Faqs />}></Route>
                <Route path='*' element={<Navigate to={'/'} />}></Route>
            </Route>
        </Routes>
    )
}
