import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
// import { Fade } from 'react-reveal'
import { DashboardCard } from '../components/dashboard/DashboardCard'
import { DashboardExpertise } from '../components/dashboard/DashboardExpertise'
import { DashboardFeatersTechnology } from '../components/dashboard/DashboardFeatersTechnology'
import { DashboardArealProject } from '../components/dashboard/DashboardArealProject'
import { DashboardGallery } from '../components/dashboard/DashboardGallery'
import { DashboardOurTeam } from '../components/dashboard/DashboardOurTeam'
import { DashboardTestimonial } from '../components/dashboard/DashboardTestimonial'

export const Home = () => {
  return (
    <div>
      <div className='av-bg-primary'>
        <div className='home-bg-img' >
          <Container maxWidth="md" >
            <Grid container display={'flex'} justifyContent={'center'}>
              <Grid xs={12} md={8} mt={0}>
                {/* <Fade left> */}
                <img src="assets/drone-1.webp" alt="" className='ratio-1x1 ratio ' />
                {/* </Fade > */}
              </Grid>
            </Grid>
            <Grid container display={'flex'} justifyContent={'center'} marginTop={5} >
              <Grid item sm={12} md={8}>
                {/* <img src="assets/element-blur-2 1.webp" alt="" className='blur-section' /> */}
                {/* <Fade right> */}
                <Typography className='ff-mi' variant='h3' textAlign={'center'} color={'white'}>PM Drones revolutionizing farming Industry</Typography>
                {/* </Fade> */}
              </Grid>
            </Grid>
          </Container>
        </div>
        <Container maxWidth="xl" sx={{ paddingBottom: 5 }}>
          <Grid container display={'flex'} justifyContent={'center'} marginTop={5} >
            <Grid item sm={12} md={8}>
              <Typography className='ff-mo ts-15' textAlign={'center'} color={'white'}>Transform your farming operations with Agrivimaan’s advanced technology,
                maximizing yields and streamlining crop management.</Typography>
            </Grid>
          </Grid>
          {/* <Grid container display={'flex'} justifyContent={'center'} marginTop={5} >
            <button className='btn btn-primary rounded-1 my-auto py-2 px-4 ff-mo bg-btn-g shadow' >LEARN DETAIL</button>
          </Grid> */}
          <DashboardCard />
        </Container>
      </div>
      <Container maxWidth="lg">
        <DashboardExpertise />
      </Container>
      <div className='bg-secondary bg-opacity-25'>
        <DashboardFeatersTechnology />
      </div>
      <Container maxWidth="lg">
        <DashboardArealProject />
      </Container>
      <div className='av-bg-primary mt-5'>
        <Container maxWidth="lg">
          <DashboardGallery />
        </Container>
      </div>
      <div className='bg-secondary bg-opacity-25'>
        <Container maxWidth="lg">
          <DashboardOurTeam />
        </Container>
      </div>
      <Container maxWidth="lg">
        <DashboardTestimonial />
      </Container>
    </div>
  )
}
