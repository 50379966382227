import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { sendSESMail } from '../../services/MailService'
const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const PricingCTA = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [isInValidEmail, setIsInValidEmail] = useState(false)
    const [submit, setSubmit] = useState(false)

    const sendMail = async () => {
        setSubmit(true)
        const subject = 'Booking Request'
        if (email === "" || !regexEmail.test(email)) {
            setIsInValidEmail(true)
        } else {
            setIsInValidEmail(false)
        }
        if (name === "" || isInValidEmail || message === "") {
            return
        }
        const template = `<p>Dear Agrivimaan, ,<br/>
        Mr/Mrs <b>${name}</b> Requesting to booking using email "<b>${email}</b>
        for <b>${message}</b> Please verify</p>`
        console.log(subject, template)
        await sendSESMail(subject, template)
        setSubmit(false)
        setName('')
        setEmail('')
        setMessage('')
    }
    const enterEmail = (e) => {
        setEmail(e.target.value)
        if (!regexEmail.test(e.target.value)) {
            setIsInValidEmail(true)
        } else {
            setIsInValidEmail(false)
        }
    }
    return (
        <div>
            <Grid container>
                <Grid container item xs={12} md={8} bgcolor={'#0F2946'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={11} sm={6}>
                        <Typography className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>CTA</Typography>
                        <Typography className='ff-mi' variant='h4' marginY={2} color={'white'}>Explore Your World Without Limitations</Typography>
                        {/* <div className='d-flex justify-content-start' style={{ marginTop: -140 }}>
                            <img src="assets/element-blur-2 1.webp" alt="" height={200} />
                        </div> */}
                        <Typography color={'white'} className='ff-mo' mt={-3}>
                            Embrace the freedom to explore the world without
                            limitations, breaking barriers, embracing diversity,
                            and nurturing intellectual curiosity for limitless
                            personal growth.
                        </Typography>
                        {/* <Typography my={2} sx={{ display: 'flex', justifyContent: 'center' }}><button className='btn btn-primary rounded-1 btn-sm my-auto py-2 ts-14 ff-mo bg-btn-g' >GET STARTED</button></Typography> */}

                        {/* <div>
                            <div className="mb-3 mt-4">
                                <input type="text" value={name} onChange={(e) => { setName(e.target.value) }} className={`form-control rounded-1 ${submit && !name ? 'is-invalid' : ''}`} placeholder="Name" />
                            </div>
                            <div className="mb-3">
                                <input type="email" value={email} onChange={enterEmail} className={`form-control rounded-1 ${submit && isInValidEmail ? 'is-invalid' : ''}`} placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <textarea value={message} onChange={(e) => { setMessage(e.target.value) }} className={`form-control rounded-4 ${submit && !message ? 'is-invalid' : ''}`} rows="3" placeholder='Message'></textarea>
                            </div>
                            <div className="mb-3">
                                <button className="form-control btn btn-primary rounded-1" onClick={sendMail}>SEND</button>
                            </div>
                        </div> */}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img src="assets/Frame_36_(2).webp" alt="" width={'100%'} height={'100%'} />
                </Grid>
            </Grid>
            <div className='bg-secondary bg-opacity-25'>
                <Container maxWidth="lg">
                    <Grid container display={'flex'} justifyContent={'center'} py={5}>
                        <Grid item xs={12} md={7}>
                            <Typography className='ff-mi fw-bold' variant='h6' textAlign={'center'} color={'#1D69E7'}>FEATURED TECHNOLOGY</Typography>
                            <Typography textAlign={'center'} className='ff-mi' variant='h4' marginY={2}>New Technology and Feature Our Drone</Typography>
                            {/* <div className='d-flex justify-content-start' style={{ marginTop: -120 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}
                            <Typography textAlign={'center'} className='ff-mo text-secondary' mb={2}>New advanced architecture with power efficient motor and high-performance battery</Typography>
                        </Grid>
                    </Grid>
                    <Grid container pb={6}>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src="assets/icons/Group_13.svg" alt="" />
                            </div>
                            <Typography textAlign={'center'} className='fw-bold ff-mo ts-15 my-3'>Select Your
                                Service</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src="assets/icons/Group_(7).svg" alt="" />
                            </div>
                            <Typography textAlign={'center'} className='fw-bold ff-mo ts-15 my-3'>Booking & Confirm</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src="assets/icons/Group_15.svg" alt="" />
                            </div>
                            <Typography textAlign={'center'} className='fw-bold ff-mo ts-15 my-3'>Booking Payment (Online)</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src="assets/icons/Group_14.svg" alt="" />
                            </div>
                            <Typography textAlign={'center'} className='fw-bold ff-mo ts-15 my-3'>Enjoy Your Service</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div>
                <Container maxWidth="lg">
                    <Grid container display={'flex'} justifyContent={'center'} marginY={15}>
                        <Grid item xs={9} bgcolor={'#0F2946'} padding={5} borderRadius={5}>
                            <Grid display={'flex'} justifyContent={'space-between'}>
                                <img src="assets/Frame_22.webp" alt="" style={{ marginTop: -130, marginLeft: -100 }} />
                                <img src="assets/Frame_24.webp" alt="" height={130} width={130} style={{ marginTop: -110 }} />
                            </Grid>
                            <Typography textAlign={'center'} className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>NEED AGRIVIMAAN</Typography>
                            <Typography textAlign={'center'} color={'white'} className='ff-mi' variant='h4' marginY={2}>Get Our Service Easily</Typography>
                            {/* <div className='d-flex justify-content-center'>
                                <img src="assets/element-blur-2 1.webp" alt="" height={150} style={{ marginTop: -120, marginLeft: -170 }} />
                            </div> */}
                            <Grid container display={'flex'} justifyContent={'center'} mt={5}>
                                <Grid item xs={12} sm={6} mb={2} px={2}>
                                    <input type="text" placeholder='Full Name' value={name} onChange={(e) => { setName(e.target.value) }}
                                        className={`form-control rounded-1 bg-white ${submit && !name ? 'is-invalid error' : ''}`} />

                                </Grid>
                                <Grid item xs={12} sm={6} mb={2} px={2}>
                                    <input type="text" placeholder='Email' value={email} onChange={enterEmail}
                                        className={`form-control rounded-1 bg-white ${submit && isInValidEmail ? 'is-invalid error' : ''}`} />

                                </Grid>
                                <Grid item xs={12} px={2} mb={2}>
                                    <textarea rows={4} placeholder='Your Request' value={message} onChange={(e) => { setMessage(e.target.value) }}
                                        className={`form-control rounded-1 bg-white ${submit && !message ? 'is-invalid error' : ''}`} />
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <button className='btn btn-sm btn-primary rounded-1 px-3 ms-2' onClick={sendMail} >BOOKING NOW</button>
                                </Grid>

                            </Grid>
                            <Grid display={'flex'} justifyContent={'start'}>
                                <img src="assets/Frame_23.webp" alt="" height={130} width={130} style={{ marginBottom: -110 }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    )
}
