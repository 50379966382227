import { Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'

export const DashboardFeatersTechnology = () => {
  const [count1, setCount1] = useState(450)
  const [count2, setCount2] = useState(300)
  const [count3, setCount3] = useState(225)
  // const countChanges1 = () => {
  //   let val1 = 0
  //   setTimeout(() => {
  //     if (450 > val1) {
  //       val1 ++
  //       setCount1(val1)
  //       countChanges1()
  //     }
  //   }, 10);
  // }
  // const countChanges2 = () => {
  //   let val2 = 0
  //   setTimeout(() => {
  //     if (300 > val2) {
  //       val2 ++
  //       setCount2(val2)
  //       countChanges2()
  //     }
  //   }, 15);
  // }
  // const countChanges3 = () => {
  //   let val3 = 0
  //   setTimeout(() => {
  //     if (225 > val3) {
  //       val3 ++
  //       setCount3(val3)
  //       countChanges3()
  //     }
  //   }, 20);
  // }
  // useEffect(() => {
  //   countChanges1()
  //   countChanges2()
  //   countChanges3()
  //   setInterval(() => {
  //     countChanges1()
  //     countChanges2()
  //     countChanges3()
  //   }, 15000);

  // }, [])
  return (
    <div className=''>
      <div className='d-flex justify-content-center'>
        <img src="assets/drone-1.webp" alt="" className='position-absolute z-2' width="250px" style={{ marginTop: "-70px" }} />
        <img src="assets/field-2.webp" alt="" width="100%" className='position-relative z-1' />
      </div>
      {/* <Grid container display="flex" justifyContent="end" position="relative" marginTop="-40px" zIndex={999}>
        <Grid container item xs={7} bgcolor="white" borderRadius="15px 0px 0px 15px" paddingY="10px">
          <Grid item xs={12} sm={4}>
            <Typography variant='h4' textAlign="center" color="#1D69E7" className='ff-mi'>{count1}+</Typography>
            <Typography textAlign="center">Happy Client</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h4' textAlign="center" color="#1D69E7" className='ff-mi'>{count2}+</Typography>
            <Typography textAlign="center">Happy Client</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h4' textAlign="center" color="#1D69E7" className='ff-mi'>{count3}+</Typography>
            <Typography textAlign="center">Happy Client</Typography>
          </Grid>
        </Grid>
      </Grid> */}
      <Container maxWidth="lg">
        <Grid container mt={5} mb={{ xs: 5, md: 0 }} display={'flex'} justifyContent={'center'}>
          <Grid item sm={12} md={8} >
            <Typography textAlign={'center'} className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>FEATURED TECHNOLOGY</Typography>
            <Typography textAlign={'center'} className='ff-mi' variant='h4' marginY={2}>New Technology and Feature Our Drone</Typography>
            {/* <div className='d-flex justify-content-start px-5' style={{ marginTop: -100 }}>
              <img src="assets/element-blur-2 1.webp" alt="" height={150} />
            </div> */}
            <Typography textAlign={'center'} className='ff-mo' color={'#656565'}>New advanced architecture with power efficient motor and high-performance battery</Typography>
          </Grid>
        </Grid>
        <div className='d-flex justify-content-center'>
          <Grid sx={{ marginTop: { xs: '0px', md: '180px', position: 'absolute' } }}>
            <div>
              <img src="assets/drone-1.webp" alt="" className='position-absolute z-2' height={'200px'} style={{ marginLeft: '-168px', marginTop: '-30px' }} />
              <div className='position-absolute bg-secondary bg-opacity-50 rounded-circle z-1' style={{ height: 230, width: 230, marginLeft: "-110px", marginTop: '-20px' }}></div>
            </div>
          </Grid>
        </div>
        <Grid container display={'flex'} justifyContent={'space-between'} sx={{ marginTop: { xs: '160px', md: '50px' } }}>
          <Grid item xs={12} md={4} marginTop={2}>
            <div className='rounded-circle d-flex justify-content-center align-items-center bg-primary bg-opacity' style={{ height: '80px', width: '80px' }}>
              <img src="assets/icons/Group_5.svg" alt="" />
            </div>
            <Typography marginTop={2} variant='h6' className='fw-bold ff-mi px-2' textAlign={'start'}>Wheelbase</Typography>
            <Typography marginTop={2} className='fs-13 ff-mo' color={'#656565'} textAlign={'start'}>Impact on stability, maneuverability, and payload capacity. Choose longer for stability, shorter for agility. Tailor to field size, terrain, and payload needs.</Typography>
          </Grid>
          <Grid item xs={12} md={4} marginTop={2}>
            <div className='d-flex justify-content-end'>
              <div className='rounded-circle d-flex justify-content-center align-items-center bg-primary bg-opacity' style={{ height: '80px', width: '80px' }}>
                <img src="assets/icons/Group_7.svg" alt="" />
              </div>
            </div>
            <Typography marginTop={2} variant='h6' className='fw-bold ff-mi px-2' textAlign={'end'}>Folding size</Typography>
            <Typography marginTop={2} className='fs-13 ff-mo' color={'#656565'} textAlign={'end'}>Enhances portability, space efficiency, quick deployment, versatility, and convenience. Consider build quality and reliability for optimal performance and durability.</Typography>
          </Grid>
        </Grid>
        <Grid container display={'flex'} justifyContent={'space-around'} paddingBottom={5}>
          <Grid item xs={12} md={4} marginTop={2}>
            <div className='rounded-circle d-flex justify-content-center align-items-center bg-primary bg-opacity' style={{ height: '80px', width: '80px' }}>
              <img src="assets/icons/_x31_0.svg" alt="" />
            </div>
            <Typography marginTop={2} variant='h6' className='fw-bold ff-mi px-2' textAlign={'start'}>Extended size</Typography>
            <Typography marginTop={2} className='fs-13 ff-mo' color={'#656565'} textAlign={'start'}>Benefits include increased payload, longer range, improved stability, and greater flexibility. Consider trade-offs like weight and regulations. Tailor to specific operational needs and applications.</Typography>
          </Grid>
          <Grid item xs={12} md={4} marginTop={2}>
            <div className='d-flex justify-content-end'>
              <div className='rounded-circle d-flex justify-content-center align-items-center bg-primary bg-opacity' style={{ height: '80px', width: '80px' }}>
                <img src="assets/icons/Group_6.svg" alt="" />
              </div>
            </div>
            <Typography marginTop={2} variant='h6' className='fw-bold ff-mi px-2' textAlign={'end'}>Motor</Typography>
            <Typography marginTop={2} className='fs-13 ff-mo' color={'#656565'} textAlign={'end'}>Powerful brushless motors drive agricultural drones for efficient propulsion, while motor control ensures stability. Consider thrust, Kv rating, cooling, and redundancy for optimal performance and safety.</Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
