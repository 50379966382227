import { Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { sendSESMail } from '../../services/MailService'

export const AboutUsOurClient = () => {
    const [email, setEmail] = useState('')
    const [submit, setSubmit] = useState(false)
    const [isInValidEmail, setIsInValidEmail] = useState(false)
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const sendMail = async () => {
        setSubmit(true)
        const subject = 'Explore Project'
        const template = `<p>Dear Agrivimaan,<br/> 
    This request coming from <b>"${email}"</b> Plaese Verify</p>`
        if (email === "" || !regex.test(email)) {
            setIsInValidEmail(true)
            return
        } else {
            setIsInValidEmail(false)
        }
        console.log(subject, template)
        await sendSESMail(subject, template);
        setEmail('')
        setSubmit(false);

    }
    const enterEmail = (e) => {
        setEmail(e.target.value)
        if (!regex.test(e.target.value)) {
            setIsInValidEmail(true)
        } else {
            setIsInValidEmail(false)
        }
    }
    return (
        <div>
            {/* <div className='bg-secondary bg-opacity-25'>
                <Container maxWidth="lg">
                    <Grid paddingY={10}>
                        <Typography textAlign={'center'} className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>OUR CLIENT</Typography>
                        <Grid container display={'flex'} justifyContent={'center'}>
                            <Grid item xs={12} md={7}>
                                <Typography textAlign={'center'} className='ff-mi' variant='h4' marginY={2}>Some Big Companies That Believe in Us</Typography>
                                <div className='d-flex justify-content-start' style={{ marginTop: -100 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div> */}
            <Container maxWidth="lg">
                <Grid container display={'flex'} justifyContent={'center'} marginY={15}>
                    <Grid item xs={9} bgcolor={'#0F2946'} padding={5} borderRadius={5}>
                        <Grid display={'flex'} justifyContent={'space-between'}>
                            <img src="assets/Frame_22.webp" alt="" style={{ marginTop: -130, marginLeft: -100 }} />
                            <img src="assets/Frame_24.webp" alt="" height={130} width={130} style={{ marginTop: -110 }} />
                        </Grid>
                        <Typography textAlign={'center'} className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>LET'S FLY</Typography>
                        <Typography textAlign={'center'} color={'white'} className='ff-mi' variant='h4' marginY={2}>Start Your Awesome
                            Agricultural Project With Us.</Typography>
                        {/* <div className='d-flex justify-content-start'>
                            <img src="assets/element-blur-2 1.webp" alt="" height={150} style={{ marginTop: -100 }} />
                        </div> */}
                        <Typography textAlign={'center'} color={'white'} className='ff-mo'>Join us as we harness the power of drone technology to
                            revolutionize farming.</Typography>
                        <Grid container display={'flex'} justifyContent={'center'} mt={5}>
                            <Grid container item spacing={2} xs={12} sm={8} mb={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Grid item xs={12} sm={7} md={8} width={'100%'}>
                                    <input type="text" placeholder='Your Email here' value={email} onChange={enterEmail}
                                        className={`form-control rounded-1 input ${submit && isInValidEmail ? 'is-invalid error' : ''}`} style={{ backgroundColor: '#113564', color: 'white' }} />
                                </Grid>
                                <Grid item xs={8} sm={5} md={4} width={'100%'}>
                                    <button className='btn btn-primary form-control rounded-1' onClick={sendMail} >EXPLORE</button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid display={'flex'} justifyContent={'start'}>
                            <img src="assets/Frame_23.webp" alt="" height={130} width={130} style={{ marginBottom: -110 }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
