import { CheckCircle } from '@mui/icons-material'
// import { Animation } from 'react-animate-style'
import { Card, CardContent, Checkbox, Container, FormControlLabel, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { PricingFeatursAndTechnology } from '../components/pricing/PricingFeatursAndTechnology'
import { PricingCTA } from '../components/pricing/PricingCTA'
import { sendSESMail } from '../services/MailService'
const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const regexPhone = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/

export const PriceDrone = () => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [isInValidPhone, setIsInValidPhone] = useState(false)
    const [isInValidEmail, setIsInValidEmail] = useState(false)
    const [requireType, setRequiredType] = useState('Drone Spray')
    const [permissionCheck, setPermissionCheck] = useState(true)
    const [policyCheck, setPolicyCheck] = useState(true)
    const [submit, setSubmit] = useState(false)
    const sendMail = async () => {
        // let token = captchaRef.current.getValue();
        // try {
        //     let response = await axios.get(`https://www.google.com/recaptcha/api/siteverify?secret=${SECRET_KEY}&response=${token}`)
        setSubmit(true)
        const subject = 'Get in touch'
        if (email === "" || !regexEmail.test(email)) {
            setIsInValidEmail(true)
        } else {
            setIsInValidEmail(false)
        }
        if (phone === "" || !regexPhone.test(phone)) {
            setIsInValidPhone(true)
        } else {
            setIsInValidPhone(false)
        }
        if (name === "" || isInValidEmail || message === "" || isInValidPhone) {
            return
        }
        // if (response.data.success) {
        const template = ` <p>Dear Agrivimaan,<br/> 
                This request coming from <b>"${email}"</b> Plaese Verify</p><br/>
                <table border=1>
                <caption style="display: table-caption;caption-side:top;text-align:center">Connect Details</caption>
                <tr><th style="text-align:left;width:50%">Name</th><td>${name}</td></tr>
                <tr><th style="text-align:left;width:50%">Email</th><td>${email}</td></tr>
                <tr><th style="text-align:left;width:50%">Contact Number</th><td>${phone}</td></tr>
                    <tr><th style="text-align:left;width:50%">Message</th><td>${message}</td></tr>
                    <tr><th style="text-align:left;width:50%">Require details for</th><td>
                    ${requireType}</td></tr>
                    <tr><th style="text-align:left;width:50%">AgriVimaan may email and call me with personalized messages and event news.</th><td>${permissionCheck}</td></tr>
                    <tr><th style="text-align:left;width:50%">By providing the required details you acknowledge and provide your express consent that you have read the Privacy Policy as available on our website.</th><td>${policyCheck}</td></tr>
                    </table>`
        console.log(subject, template)
        await sendSESMail(subject, template)
        setSubmit(false)
        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
        setRequiredType('Drone Spray')
        setPermissionCheck(true)
        setPolicyCheck(true)
        // captchaRef.current.reset();
        //     } else {
        //         toast.error('Captcha verification required !', options)
        //     }
        // } catch (error) {

        // }

    }
    const enterEmail = (e) => {
        setEmail(e.target.value)
        if (!regexEmail.test(e.target.value)) {
            setIsInValidEmail(true)
        } else {
            setIsInValidEmail(false)
        }
    }
    const enterPhone = (e) => {
        setPhone(e.target.value)
        if (!regexPhone.test(e.target.value)) {
            setIsInValidPhone(true)
        } else {
            setIsInValidPhone(false)
        }
    }
    return (
        <div>
            <div className='about-bg-img'>
                <Container maxWidth='lg'>
                    <Grid display={'flex'} justifyContent={'center'} minHeight={400} alignItems={'center'}>
                        {/* <Animation isVisible animationIn='zoomIn'> */}
                        <Typography variant='h3' className='ff-mi' color={'white'} textAlign={'center'}>Pricing</Typography>
                        {/* </Animation> */}
                    </Grid>
                </Container>
            </div>
            <div className='bg-secondary bg-opacity-25 pt-2 pb-5'>
                <Container maxWidth="lg">
                    <Grid container display={'flex'} justifyContent={'center'} pt={5}>
                        <Grid item xs={12} md={6}>
                            <Typography className='ff-mi fw-bold' variant='h6' textAlign={'center'} color={'#1D69E7'}>PRICING PLAN</Typography>
                            <Typography textAlign={'center'} className='ff-mi' variant='h4' marginY={2}>Contact Us</Typography>
                            {/* <div className='d-flex justify-content-center' style={{ marginTop: -100 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}
                            <Typography className='text-secondary ts-15 text-center' mt={-3}>Choose a plan that works best for your business</Typography>

                        </Grid>
                    </Grid>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-around', my: 5 }}>
                        {/* <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Card variant='outlined' sx={{ maxWidth: '300px', my: 2 }} className='rounded-4 bg-light p-2 shadow' colour="light">
                            <CardContent>
                                <Typography my={2} className='fw-bold ff-mo'>Base</Typography>
                                <Typography my={2} className='ff-mi'>₹10,000/Month</Typography>
                                <Typography my={2}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit ipsa dolor minus quo incidunt? Magnam</Typography>
                                <Typography my={2}><CheckCircle color='primary' fontSize='small' /> Advanced-level drones</Typography>
                                <Typography my={2}><CheckCircle color='primary' fontSize='small' /> 3-4 professional pilots</Typography>
                                <Typography my={2}><CheckCircle color='primary' fontSize='small' /> 45-55 minutes fly</Typography>
                                <Typography my={2}><CheckCircle color='primary' fontSize='small' /> Free transport fee</Typography>
                                <Typography my={2} sx={{ display: 'flex', justifyContent: 'center' }}><button className='btn btn-outline-primary rounded-1 btn-sm my-auto py-2 ts-14 ff-mo' >BOOK NOW</button></Typography>
                            </CardContent>
                        </Card>
                    </Grid> */}
                        <Grid item xs={12} sm={10} md={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ maxWidth: '100%', bgcolor: '#0F2946', my: 2 }} className='rounded-4 text-white p-2 shadow' variant='outlined'>
                                {/* <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <div>
                                        <div className='rounded-circle position-absolute d-flex justify-content-center align-items-center' style={{ backgroundColor: '#F67036', height: 50, width: 50, marginTop: -35 }}>
                                            <img className='me-1' src="assets/icons/Vector (5).svg" alt="" />
                                        </div>
                                    </div>
                                </Grid> */}
                                <CardContent>
                                    {/* <Grid container > */}
                                    <Grid container bgcolor={'#0F2946'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Grid item xs={11} sm={9} lg={6} >
                                            <Typography className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>Let’s Connect</Typography>
                                            {/* <Typography className='ff-mi' variant='h4' marginY={2} color={'white'}>Get in touch</Typography> */}
                                            {/* <img src="assets/element-blur-2 1.webp" alt="" className='blur-section-6' /> */}
                                            <Typography color={'white'} className='ff-mo ts-14'>
                                                Our team of experts will be happy to provide you with detailed information, answer your questions, and discuss how our agri drone technology can benefit your specific needs.
                                            </Typography>
                                            <div>
                                                <div className="mb-3 mt-4">
                                                    <input type="text" value={name} onChange={(e) => { setName(e.target.value) }} className={`form-control rounded-1 ${submit && !name ? 'is-invalid error' : ''}`} placeholder="Name" />
                                                </div>
                                                <div className="mb-3">
                                                    <input type="email" value={email} onChange={enterEmail} className={`form-control rounded-1 ${submit && isInValidEmail ? 'is-invalid error' : ''}`} placeholder="Email" />
                                                </div>
                                                <div className="mb-3">
                                                    <input type="text" value={phone} onChange={enterPhone} className={`form-control rounded-1 ${submit && isInValidPhone ? 'is-invalid error' : ''}`} placeholder="Phone" />
                                                </div>
                                                <div className="mb-3">
                                                    <textarea value={message} onChange={(e) => { setMessage(e.target.value) }} className={`form-control rounded-1 ${submit && !message ? 'is-invalid error' : ''}`} rows="3" placeholder='Message'></textarea>
                                                </div>
                                                <Grid container display={'flex'} alignItems={'center'} mb={3}>
                                                    <Grid item xs={12} sm={5}>
                                                        <Typography className='text-white text-start'>Require details for*</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={7}>
                                                        <select className="form-select" value={requireType} onChange={(e) => { setRequiredType(e.target.value) }}>
                                                            <option value="Drone Spray">Drone Spray</option>
                                                            <option value="Purchase a Drone">Purchase a Drone</option>
                                                            <option value="Become a Drone Partner">Become a Drone Partner</option>
                                                            <option value="Others">Others</option>
                                                        </select>
                                                    </Grid>
                                                </Grid>
                                                {/* <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Grid item xs={12} md={4} >
                                    <Typography className='text-white' sx={{ textAlign: { xs: 'start', md: 'center' } }}>Require details for*</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        defaultValue={'Drone Spray'}
                                        value={requireType}
                                        onChange={(e) => { setRequiredType(e.target.value) }}
                                    >
                                        <FormControlLabel className='text-white' value="Drone Spray" control={<Radio sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'primary',
                                            },
                                        }} />} label="Drone Spray" />
                                        <FormControlLabel className='text-white' value="Purchase a Drone" control={<Radio sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'primary',
                                            },
                                        }} />} label="Purchase a Drone" />
                                        <FormControlLabel className='text-white' value="Become a Drone Partner" control={<Radio sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'primary',
                                            },
                                        }} />} label="Become a Drone Partner" />
                                        <FormControlLabel className='text-white' value="Others" control={<Radio sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'primary',
                                            },
                                        }} />} label="Others" />

                                    </RadioGroup>
                                </Grid>
                            </Grid> */}
                                                <FormControlLabel className='text-white' control={<Checkbox value={permissionCheck}
                                                    sx={{
                                                        color: 'white',
                                                        '&.Mui-checked': {
                                                            color: 'primary',
                                                        },
                                                    }} onChange={(e) => { setPermissionCheck(e.target.value) }} defaultChecked />} label="AgriVimaan may email and call me with personalized messages and event news." />

                                                <FormControlLabel className='text-white' control={<Checkbox value={policyCheck}
                                                    sx={{
                                                        color: 'white',
                                                        '&.Mui-checked': {
                                                            color: 'primary',
                                                        },
                                                    }} onChange={(e) => { setPolicyCheck(e.target.value) }} defaultChecked />} label="By providing the required details you acknowledge and provide your express consent that you have read the Privacy Policy as available on our website." />
                                                <div className="my-3">
                                                    <button className="form-control btn btn-primary rounded-1" onClick={sendMail}>SEND</button>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item xs={12} md={4}>
                                            <img src="assets/Frame 35.webp" alt="" width={'100%'} height={'100%'} />
                                        </Grid> */}
                                    {/* </Grid> */}
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Card sx={{ maxWidth: '300px', my: 2 }} className='rounded-4 bg-white p-2 shadow' variant='outlined'>
                            <CardContent>
                                <Typography my={2} className='fw-bold ff-mo'>Premium</Typography>
                                <Typography my={2} className='ff-mi'>₹10,000/Month</Typography>
                                <Typography my={2}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit ipsa dolor minus quo incidunt? Magnam</Typography>
                                <Typography my={2}><CheckCircle color='primary' fontSize='small' /> Advanced-level drones</Typography>
                                <Typography my={2}><CheckCircle color='primary' fontSize='small' /> 3-4 professional pilots</Typography>
                                <Typography my={2}><CheckCircle color='primary' fontSize='small' /> 45-55 minutes fly</Typography>
                                <Typography my={2}><CheckCircle color='primary' fontSize='small' /> Free transport fee</Typography>
                                <Typography my={2} sx={{ display: 'flex', justifyContent: 'center' }}><button className='btn btn-primary rounded-1 btn-sm my-auto py-2 ts-14 ff-mo' >BOOK NOW</button></Typography>
                            </CardContent>
                        </Card>
                    </Grid> */}
                    </Grid>
                </Container>
            </div>
            <div className='pt-5'>
                <PricingFeatursAndTechnology />
            </div>
            <div>
                <PricingCTA />
            </div>
        </div>
    )
}
