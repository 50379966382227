import { Grid, Typography } from '@mui/material'
import React from 'react'

export const ProductOperation = () => {
    return (
        <div>
            <div className='bg-secondary bg-opacity-25 product-bg-img1'>
                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} pb={2}>
                    <Grid item xs={12} md={5} display={'flex'} justifyContent={'start'}>
                        <img src="assets/drone-console.webp" alt="" style={{ maxWidth: '100%' }} />
                    </Grid>
                    <Grid container item xs={12} md={7} display={'flex'} justifyContent={'center'}>
                        <Grid item xs={11} md={9}>
                            <Typography className='ff-mi' variant='h4' marginY={2}>Compact for one man operability</Typography>
                            {/* <div className='d-flex justify-content-start' style={{ marginTop: -100 }}><img src="assets/element-blur-2 1.web" alt="" height={150} /></div> */}
                            <Typography color={'#656565'}>The H12 series uses newest Qualcomm Snapdragon 625 processor, equipped with an Android embedded system and advanced SDR technology and super protocol stack, making the image clearer, lower the latency, longer distance.</Typography>
                        </Grid>

                    </Grid>

                </Grid>
            </div>
            <div className='av-bg-primary product-bg-img2'>
                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid container item xs={12} md={6} display={'flex'} justifyContent={'center'}>
                        <Grid item xs={11} md={10}>
                            <Typography className='ff-mi' variant='h4' color={'white'} marginY={2}>Intuitive and easy to use</Typography>
                            {/* <div className='d-flex justify-content-start' style={{ marginTop: -100 }}><img src="assets/element-blur-2 1.web" alt="" height={150} /></div> */}
                            <Typography color={'#DCDCDC'}>With the help of software, user can directly mark
                                coordinates point to the field to which the chemical
                                needs to be sprayed and the drone can automatically
                                take off, once task is complete the drone return to
                                the default coordinates/start coordinates</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5} >
                        <img src="assets/drone-view.webp" width={'100%'} alt="" />
                    </Grid>
                </Grid>
            </div>
            <div className='bg-secondary bg-opacity-25 product-bg-img3'>
                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={12} md={7} display={'flex'} justifyContent={'start'}>
                        <img src="assets/drone-camera.webp" width={'100%'} alt="" />
                    </Grid>
                    <Grid container item xs={12} md={5} display={'flex'} justifyContent={'center'} py={2}>
                        <Grid item xs={11} md={9} >
                            <Typography className='ff-mi' variant='h4' marginY={2}>24.1 MP Imaging
                                Payload</Typography>
                            {/* <div className='d-flex justify-content-start' style={{ marginTop: -100 }}><img src="assets/element-blur-2 1.web" alt="" height={150} /></div> */}
                            <Typography color={'#656565'}>11L Quadra carries a 24.1 MP imaging camera with an APS-C sensor for capturing sharp, high-resolution images. The camera is mounted nadir-facing on a custom designed mount to reduce the effect of vibrations on the images.</Typography>
                        </Grid>

                    </Grid>

                </Grid>
            </div>
        </div>
    )
}
