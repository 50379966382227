import { Grid, Typography } from '@mui/material'
// import { Animation } from 'react-animate-style'
import React from 'react'

export const DashboardExpertise = () => {
    return (
        <div>
            <Grid container display="flex" marginY="100px" justifyContent="space-between">
                <Grid item xs={12} md={4} >
                    <Grid display={'flex'} justifyContent={'end'} >
                        <Grid item xs={12} md={9}>
                            {/* <Animation isVisible animationIn='fadeInDown'> */}
                            <Typography className='ff-mi fw-bold' variant='h6' color={'#1D69E7'}>Our Expertise</Typography>
                            {/* </Animation> */}
                            {/* <Animation animationInDuration={2000} isVisible animationIn='fadeInLeft' animationInDelay={2000}> */}
                            <Typography className='ff-mi mt-2' variant='h4'>We Are
                                Different &
                                Always
                                Professional
                            </Typography>
                            {/* </Animation> */}
                            {/* <div className='d-flex justify-content-start' style={{ marginTop: -120 }}>
                                <img src="assets/element-blur-2 1.webp" alt="" height={150} />
                            </div> */}
                        </Grid>
                    </Grid>
                    <Grid sx={{ minHeight: 345, mt: 5 }}>
                        {/* <Animation isVisible animationIn='zoomIn'> */}
                        <img src="assets/element-ellipse-2_1.webp" alt="" className='position-absolute z-1' style={{ maxWidth: '100%' }} />
                        {/* </Animation> */}
                        {/* <Animation animationInDuration={2000} isVisible animationIn='fadeInLeft' animationInDelay={2000}> */}
                        <img src="assets/drone-2.webp" alt="" className='position-relative z-2' style={{ maxWidth: '100%' }} />
                        {/* </Animation> */}
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={7} display={'flex'} justifyContent={'space-between'}>
                    {/* <Animation animationInDuration={2000} isVisible animationIn='fadeInLeftBig' animationInDelay={1000}> */}
                    <Grid item xs={12} md={6} my={1}>
                        {/* <div className='d-flex justify-content-center align-items-center'> */}
                        <img src="assets/icons/Vector.svg" className='p-3 bg-secondary bg-opacity-25 rounded-circle' alt="" height="80px" width="80px" />
                        {/* </div> */}
                        <Typography mt={2} className='ff-pp fw-bold' variant='h6' color="black" textAlign="start">Drone piloting</Typography>
                        <Typography className='ff-pp ts-15' color="#656565" textAlign="start">The ability to fly a drone safely and effectively.</Typography>
                    </Grid>
                    {/* </Animation> */}
                    {/* <Animation animationInDuration={2000} isVisible animationIn='fadeInLeftBig'> */}
                    <Grid item xs={12} md={6} my={1}>
                        <img src="assets/icons/Group_13_(2).svg" className='bg-primary rounded-circle d-flex justify-content-center p-3' width="80px" height="80px" alt="" />

                        <Typography mt={2} className='ff-pp fw-bold' variant='h6' color="black" textAlign="start">Crop science</Typography>
                        <Typography className='ff-pp ts-15' color="#656565" textAlign="start">The knowledge of crop growth
                            and production.</Typography>

                    </Grid>
                    {/* </Animation> */}
                    {/* <Animation animationInDuration={2000} isVisible animationIn='fadeInLeftBig' animationInDelay={1000}> */}
                    <Grid item xs={12} md={6} my={1}>
                        <div className='bg-secondary bg-opacity-25 rounded-circle d-flex justify-content-center' style={{ width: "80px", height: "80px" }} >
                            <img src="assets/icons/Group_14_(2).svg" className='m-3 ratio-1x1' alt="" />
                        </div>
                        <Typography mt={2} className='ff-pp fw-bold' variant='h6' color="black" textAlign="start">Data analysis</Typography>
                        <Typography className='ff-pp ts-15' color="#656565" textAlign="start">The ability to use data collected by drones to make informed decisions about crop management.</Typography>
                    </Grid>
                    {/* </Animation> */}
                    {/* <Animation animationInDuration={2000} isVisible animationIn='fadeInLeftBig'> */}
                    <Grid item xs={12} md={6} my={1}>
                        <img src="assets/icons/Layer_16.svg" className='p-3 bg-secondary bg-opacity-25 rounded-circle' alt="" height="80px" width="80px" />
                        <Typography mt={2} className='ff-pp fw-bold' variant='h6' color="black" textAlign="start">Business development</Typography>
                        <Typography className='ff-pp ts-15' color="#656565" textAlign="start">The ability to identify and develop new markets for agricultural drones.</Typography>
                    </Grid>
                    {/* </Animation> */}
                </Grid>
            </Grid>
            <Grid container display="flex" marginY="100px" justifyContent="center" alignItems="center">
                <Grid item sm={12} md={5}>
                    <Typography className='ff-mi' variant='h4'>
                        Explore Your World
                        Without Limitations
                    </Typography>
                    {/* <img src="assets/element-blur-2 1.webp" alt="" className='blur-section-3' /> */}
                </Grid>
                <Grid item sm={12} md={6}>
                    <Typography className='ff-mo' variant='h6' color="#656565">Precision mapping, crop health insights, efficient resource management, time-saving efficiency, data-driven decisions, maximizing yields, and unlocking new possibilities.</Typography>
                </Grid>
            </Grid>
        </div>
    )
}
