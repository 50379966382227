import { Container, Grid, Typography } from '@mui/material'
// import { Animation } from 'react-animate-style'
import React from 'react'
import { DashboardCard } from '../components/dashboard/DashboardCard'
import { DroneExpertiesContent } from '../components/drone-experties/DroneExpertiesContent'

export default function DroneExperties() {
    return (
        <div>
            <div className='expert-bg-img'>
                <Container maxWidth='lg'>
                    <Grid display={'flex'} justifyContent={'center'} minHeight={400} alignItems={'center'}>
                        {/* <Animation animationIn='zoomIn' isVisible> */}
                        <Typography variant='h3' className='ff-mi' color={'white'} textAlign={'center'}>Drone-as-a-Service</Typography>
                        {/* </Animation> */}
                    </Grid>
                </Container>
            </div>
            <div className='av-bg-primary pb-5'>
                <Container maxWidth='xl' >
                    <Grid container display={'flex'} justifyContent={'center'}>
                        <Grid item xs={12} md={6}>
                            <Typography textAlign={'center'} className='ff-mi' variant='h4' color={'white'} marginY={2}>Agriculture</Typography>
                            {/* <div className='d-flex justify-content-center' style={{ marginTop: -100 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}
                            <Typography textAlign={'center'} color={'#DCDCDC'}>Increase productivity and efficiency of acres of farmland
                                with drone solutions</Typography>
                        </Grid>
                    </Grid>
                    <DashboardCard />
                </Container>
            </div>
            <DroneExpertiesContent />
        </div>
    )
}
