import { CheckCircle } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export const DashboardOurTeam = () => {
    return (
        <div className='py-5'>
            <Grid container>
                <Grid container item sm={12} md={6} display={'flex'} justifyContent={'center'}>
                    <Grid container item sm={12} md={9}>
                        {[...Array(4)].map((el, i) => (
                            <Grid key={'t' + i} padding={1} item xs={6}>
                                <img src="assets/Frame_18.webp" width={'100%'} alt="" />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Typography className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>OUR TEAM</Typography>
                    <Typography className='ff-mi' variant='h4' marginY={2}>Our Experienced Pilots</Typography>
                    {/* <img src="assets/element-blur-2 1.webp" alt="" className='blur-section-6' /> */}
                    <Typography className='ff-mo' color={'#656565'}>Our experienced pilots bring expertise and professionalism to every agriculture drone mission.</Typography>
                    <Typography className='ff-mo ts-14 mb-2 mt-5' color={'#656565'}>
                        <CheckCircle color='primary' fontSize='small' /> In-depth knowledge of drone technology and agriculture practices
                    </Typography>
                    <Typography className='ff-mo ts-14 my-2' color={'#656565'}>
                        <CheckCircle color='primary' fontSize='small' /> Precise and efficient operations.
                    </Typography>
                    <Typography className='ff-mo ts-14 my-2' color={'#656565'}>
                        <CheckCircle color='primary' fontSize='small' /> Deliver accurate data and optimal results for your farming needs.
                    </Typography>
                    <Grid display={'flex'} justifyContent={'start'} mt={5}>
                        <Link to={'/contact?dest=form'}>
                            <button className='btn btn-primary rounded-1 py-2 px-4 ff-mo bg-btn-g shadow' >JOIN OUR TEAM</button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
