import { Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
const imgList = [
  { img: '/assets/gallery-img-1.webp', title: 'gallery' },
  { img: '/assets/gallery-img-2.webp', title: 'gallery' },
  { img: '/assets/gallery-img-3.webp', title: 'gallery' },
  { img: '/assets/gallery-img-4.webp', title: 'gallery' },
]
export const Gallery = () => {
  const [itemData, setItemData] = useState([{}])
  useState(() => {
    setItemData(imgList);
    setItemData((oldData) => [...oldData, ...imgList.reverse()])
    setItemData((oldData) => [...oldData, ...imgList.reverse()])
    setItemData((oldData) => [...oldData, ...imgList.reverse()])
    setItemData((oldData) => [...oldData, ...imgList.reverse()])
  }, [])
  return (
    <div>
      <div className='gallery-bg-img'>
        <Container maxWidth='lg'>
          <Grid display={'flex'} justifyContent={'center'} minHeight={400} alignItems={'center'}>
            {/* <Animation isVisible animationIn='zoomIn'> */}
            <Typography variant='h3' className='ff-mi' color={'white'} textAlign={'center'}>Gallery</Typography>
            {/* </Animation> */}
          </Grid>
        </Container>
      </div>
      <Container maxWidth='xl'>
        <Grid container spacing={2} my={5}>
          {itemData.map((item) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={item.img}>
              <img
                width={'100%'}
                src={item.img}
                srcSet={item.img}
                alt={item.title}
                loading="lazy"
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  )
}
