import { Container, Grid, Typography } from '@mui/material'
// import { Animation } from 'react-animate-style'
import React from 'react'
import { ContactUsContent } from '../components/contact-us/ContactUsContent'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const ContactUs = () => {
    const formRef = useRef(null)
    const [params] = useSearchParams()
    const dest = params.get('dest');
    useEffect(() => {
        console.log(dest)
        if (dest) {
            scrollToForm()
        }
    }, [dest])
    const scrollToForm = () => formRef.current.scrollIntoView()
    return (
        <div>
            <div className='contact-bg-img'>
                <Container maxWidth='xl'>
                    <Grid container direction={{ xs: 'row', md: 'row-reverse' }} display={'flex'} justifyContent={'end'} minHeight={400}>
                        <Grid item xs={12} md={4} display={'flex'} justifyContent={{ xs: 'center', md: 'end' }} alignItems={'center'}>
                            {/* <Animation animationIn={'fadeInLeftBig'} isVisible={true} > */}
                            <img src="assets/drone-1.webp" alt="" style={{ maxWidth: '100%', maxHeight: '250px' }} />
                            {/* </Animation> */}
                        </Grid>
                        <Grid item xs={12} md={4} display={'flex'} justifyContent={{ xs: 'center', md: 'center' }} alignItems={'center'}>
                            {/* <Animation animationIn='wobble' isVisible animationInDelay={500}> */}
                            <Typography variant='h3' className='ff-mi' color={'white'} textAlign={'left'}>Contact Us</Typography>
                            {/* </Animation> */}
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <ContactUsContent refProp={formRef} />
        </div>
    )
}
