import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NestedMenuItem } from 'mui-nested-menu';
import MenuIcon from '@mui/icons-material/Menu';
import { blue } from '@mui/material/colors';
import { useState } from 'react';
import CascadingHoverMenus from './CascadingHoverMenus';


export const HeaderNav = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [product] = useState({
        title: 'Product',
        colorMatchKey: 'product',
        mainMenu: [
            {
                isHaveSubMenu: true,
                title: 'Drone',
                path: '/',
                subMenu: [
                    {
                        isHaveSubSubMenu: false,
                        title: '11L Quad',
                        path: '/product'
                    },
                    {
                        isHaveSubSubMenu: false,
                        title: '11L Hexa',
                        path: '/product-hexa'
                    },
                ]
            }
        ]
    })
    const [droneExpertise] = useState({
        title: 'Drone Expertise',
        colorMatchKey: 'agriculture',
        mainMenu: [
            {
                isHaveSubMenu: false,
                title: 'Agriculture',
                path: '/agriculture'
            }
        ]
    })
    const [contact] = useState({
        title: 'Contact',
        colorMatchKey: 'contact',
        mainMenu: [
            {
                isHaveSubMenu: false,
                title: 'Contact Us',
                path: '/contact'
            },
            {
                isHaveSubMenu: false,
                title: "FAQ's",
                path: '/contact-faq'
            }
        ]
    })
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    const location = useLocation()

    const handleClick = (e) => setAnchorEl(e.currentTarget);
    const handleClick2 = (e) => setAnchorEl2(e.currentTarget);
    const handleClick3 = (e) => setAnchorEl3(e.currentTarget);
    const handleClose = () => { setAnchorEl(null); setAnchorEl2(null); setAnchorEl3(null); handleCloseNavMenu() };
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position='static' sx={{ width: '100%', bgcolor: '#0F2946' }}>
            <Container maxWidth="lg">
                <Grid display={'flex'} justifyContent={'space-between'}>
                    <Link to="/">
                        <Avatar src="/assets/Artboard_3_1.webp" alt=""
                            sx={{ borderRadius: 0, width: '200px', height: '80px', alignSelf: 'center' }} />
                    </Link>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <Link className='text-decoration-none text-dark' to='/'>
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Typography color={location.pathname === '/' || location.pathname === '/gallery' ? 'primary' : 'balck'} textAlign="center">Home </Typography>
                                </MenuItem>
                            </Link>
                            <Link className='text-decoration-none text-dark' to='/aboutus'>
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Typography color={location.pathname.includes('aboutus') ? 'primary' : 'black'} textAlign="center">About Us</Typography>
                                </MenuItem>
                            </Link>
                            {/* <div>
                                <MenuItem onClick={handleClick}>
                                    <Typography color={location.pathname.includes('product') ? 'primary' : 'black'} textAlign="center">Product <KeyboardArrowDownIcon /></Typography>
                                </MenuItem>
                                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                                    <NestedMenuItem
                                        label='Drone'
                                        parentMenuOpen={open}
                                    >
                                        <Link onClick={handleClose} className='text-decoration-none' to='/product'>
                                            <MenuItem>11L Quad</MenuItem>
                                        </Link>
                                        <Link onClick={handleClose} className='text-decoration-none' to='/product-hexa'>
                                            <MenuItem>11L Hexa</MenuItem>
                                        </Link>
                                    </NestedMenuItem>
                                </Menu>
                            </div> */}
                            <div>
                                <Accordion elevation={0}>
                                    <AccordionSummary sx={{
                                        minHeight: 36,
                                        maxHeight: 36,
                                        '&.Mui-expanded': {
                                            minHeight: 36,
                                            maxHeight: 36
                                        }
                                    }}
                                        expandIcon={<KeyboardArrowDownIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography color={location.pathname.includes('product') ? 'primary' : 'black'}>Product</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ py: 0, pr: 0 }}>
                                        <div>
                                            <Accordion elevation={0}>
                                                <AccordionSummary sx={{
                                                    minHeight: 36,
                                                    maxHeight: 36,
                                                    '&.Mui-expanded': {
                                                        minHeight: 36,
                                                        maxHeight: 36,
                                                    }
                                                }}
                                                    expandIcon={<KeyboardArrowDownIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography sx={{ p: 0, m: 0 }}>Drone</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ py: 0 }}>
                                                    <Link onClick={handleClose} className='text-decoration-none text-dark' to='/product'>
                                                        <MenuItem>11L Quad</MenuItem>
                                                    </Link>
                                                    <Link onClick={handleClose} className='text-decoration-none text-dark' to='/product-hexa'>
                                                        <MenuItem>11L Hexa</MenuItem>
                                                    </Link>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            {/* <div>
                                <MenuItem onClick={handleClick2}>
                                    <Typography color={location.pathname.includes('agriculture') ? 'primary' : 'black'} textAlign="center">Drone Experties <KeyboardArrowDownIcon /></Typography>
                                </MenuItem>
                                <Menu anchorEl={anchorEl2} open={open2} onClose={handleClose}>
                                    <Link onClick={handleClose} className='text-decoration-none' to='/agriculture'>
                                        <MenuItem>Agriculture</MenuItem>
                                    </Link>
                                </Menu>
                            </div> */}
                            <div>
                                <Accordion elevation={0}>
                                    <AccordionSummary sx={{
                                        minHeight: 36,
                                        maxHeight: 36,
                                        '&.Mui-expanded': {
                                            minHeight: 36,
                                            maxHeight: 36
                                        }
                                    }}
                                        expandIcon={<KeyboardArrowDownIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography color={location.pathname.includes('agriculture') ? 'primary' : 'black'}>Drone Expertise</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ py: 0, pr: 0 }}>
                                        <Link onClick={handleClose} className='text-decoration-none text-dark' to='/agriculture'>
                                            <MenuItem>Agriculture</MenuItem>
                                        </Link>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            {/* <div>
                                <MenuItem onClick={handleClick3}>
                                    <Typography color={location.pathname.includes('price') ? 'primary' : 'black'} textAlign="center">Pricing <KeyboardArrowDownIcon /></Typography>
                                </MenuItem>
                                <Menu anchorEl={anchorEl3} open={open3} onClose={handleClose}>
                                    <Link onClick={handleClose} className='text-decoration-none' to='/price-drone'>
                                        <MenuItem>For Drone</MenuItem>
                                    </Link>
                                    <Link onClick={handleClose} className='text-decoration-none' to='/price-spray'>
                                        <MenuItem>For Spray</MenuItem>
                                    </Link>
                                </Menu>
                            </div> */}
                            <Link className='text-decoration-none text-dark' to='/price'>
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Typography color={location.pathname.includes('price') ? 'primary' : 'black'} textAlign="center">Pricing</Typography>
                                </MenuItem>
                            </Link>
                            {/* <Link className='text-decoration-none text-dark' to='/contact'>
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Typography color={location.pathname.includes('contact') ? 'primary' : 'black'} textAlign="center">Contact Us</Typography>
                                </MenuItem>
                            </Link> */}
                            <div>
                                <Accordion elevation={0}>
                                    <AccordionSummary sx={{
                                        minHeight: 36,
                                        maxHeight: 36,
                                        '&.Mui-expanded': {
                                            minHeight: 36,
                                            maxHeight: 36
                                        }
                                    }}
                                        expandIcon={<KeyboardArrowDownIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography color={location.pathname.includes('contact') ? 'primary' : 'black'}>Contact</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ py: 0, pr: 0 }}>
                                        <Link onClick={handleClose} className='text-decoration-none text-dark' to='/contact'>
                                            <MenuItem>Contact Us</MenuItem>
                                        </Link>
                                        <Link onClick={handleClose} className='text-decoration-none text-dark' to='/contact-faq'>
                                            <MenuItem>FAQ's</MenuItem>
                                        </Link>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <Link to={'/contact?dest=form'} className='text-decoration-none'>
                                <MenuItem key={'page'} onClick={handleCloseNavMenu}>
                                    <button className='btn btn-primary rounded-1 btn-sm my-auto py-2 ts-14 ff-mo bg-btn-g' >BOOK NOW</button>
                                </MenuItem>
                            </Link>
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'space-around', width: '100%' }}>
                        <Link className='text-decoration-none' to='/'>
                            <Button className='text-capitalize'
                                sx={{ color: 'white', display: 'block' }}
                            >
                                <Typography noWrap color={location.pathname === '/' || location.pathname === '/gallery' ? blue[300] : 'white'} textAlign="center">Home</Typography>
                            </Button>
                        </Link>
                        <Link className='text-decoration-none' to='/aboutus'>
                            <Button className='text-capitalize'
                                sx={{ color: 'white', display: 'block' }}
                            >
                                <Typography noWrap color={location.pathname.includes('aboutus') ? blue[300] : 'white'} textAlign="center">About Us</Typography>
                            </Button>
                        </Link>
                        <CascadingHoverMenus menuDetails={product} />
                        <CascadingHoverMenus menuDetails={droneExpertise} />

                        <Link className='text-decoration-none' to='/price'>
                            <Button className='text-capitalize'
                                sx={{ color: 'white', display: 'block' }}
                            >
                                <Typography noWrap color={location.pathname.includes('price') ? blue[300] : 'white'} textAlign="center">Pricing</Typography>
                            </Button>
                        </Link>
                        <CascadingHoverMenus menuDetails={contact} />
                        <Link to={'/contact?dest=form'} className='text-decoration-none'>
                            <button className='btn btn-primary rounded-1 btn-sm my-auto py-2 ts-14 ff-mo bg-btn-g' >BOOK NOW</button>
                        </Link>
                    </Box>
                </Grid>
            </Container>
        </AppBar>
    );
}
