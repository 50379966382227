import { CheckCircle } from '@mui/icons-material'
import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { AboutUsWhatWeDo } from '../components/about-us/AboutUsWhatWeDo'
import { AboutUsArialProject } from '../components/about-us/AboutUsArialProject'
import { DashboardGallery } from '../components/dashboard/DashboardGallery'
import { AboutUsOurClient } from '../components/about-us/AboutUsOurClient'
// import { Animation } from 'react-animate-style'

export const AboutUs = () => {
    return (
        <div>
            <div className='about-bg-img'>
                <Container maxWidth='lg'>
                    <Grid display={'flex'} justifyContent={'center'} minHeight={400} alignItems={'center'}>
                        {/* <Animation isVisible animationIn='zoomIn'> */}
                        <Typography variant='h3' className='ff-mi' color={'white'} textAlign={'center'}>About Us</Typography>
                        {/* </Animation> */}
                    </Grid>
                </Container>
            </div>
            <div className='av-bg-primary pb-5'>
                <Container maxWidth='lg'>
                    <Grid container display={'flex'} justifyContent={'center'}>
                        <Grid item sm={12} md={6} pt={8}>
                            {/* <Animation isVisible animationIn='fadeInLeft'> */}
                            <Typography className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>AGRICULTURE</Typography>
                            {/* </Animation> */}
                            {/* <Animation isVisible animationIn='fadeInLeft' animationInDelay={500}> */}
                            <Typography className='ff-mi' variant='h4' color={'white'} marginY={2}>Specializing in
                                Agri Drone Services</Typography>
                            {/* </Animation> */}
                            {/* <img src="assets/element-blur-2 1.webp" alt="" className='blur-section-6' /> */}
                            {/* <Animation isVisible animationIn='fadeInLeft' animationInDelay={1000}> */}
                            <Typography className='ff-mo' color={'white'} mt={5}>Our expertise in drone technology and
                                agriculture enables precision spraying,
                                crop health analysis, and land mapping.</Typography>
                            {/* </Animation> */}
                            {/* <Animation isVisible animationIn='fadeInLeft' animationInDelay={1500}> */}
                            <Typography className='ff-mo ts-14 mb-2 mt-4' color={'#656565'}>
                                <CheckCircle color='primary' fontSize='small' /> Precision spraying
                            </Typography>
                            {/* </Animation> */}
                            {/* <Animation isVisible animationIn='fadeInLeft' animationInDelay={2000}> */}
                            <Typography className='ff-mo ts-14 my-2' color={'#656565'}>
                                <CheckCircle color='primary' fontSize='small' /> Data-driven insights
                            </Typography>
                            {/* </Animation> */}
                            {/* <Animation isVisible animationIn='fadeInLeft' animationInDelay={2500}> */}
                            <Typography className='ff-mo ts-14 my-2' color={'#656565'}>
                                <CheckCircle color='primary' fontSize='small' /> Expert team of experienced pilots
                            </Typography>
                            {/* </Animation> */}
                            {/* <Grid display={'flex'} justifyContent={'center'} mt={5}>
                                <Animation isVisible animationIn='zoomIn'>
                                <button className='btn btn-primary rounded-1 py-2 px-4 ff-mo shadow'>LEARN MORE</button>
                                </Animation>
                            </Grid> */}
                        </Grid>
                        <Grid container sm={12} md={4} display={'flex'} justifyContent={'center'}>
                            <Grid padding={1} item xs={9}>
                                {/* <Animation isVisible animationIn='fadeInRight' animationInDuration={2000}> */}
                                <div className='d-flex justify-content-end'>
                                    <img src="assets/element-ellipse-1-300x300_1.webp" alt="" style={{ height: 150, marginBottom: -85, marginRight: -65 }} />
                                </div>
                                <img src="assets/Frame_21.webp" width={'100%'} alt="" />
                                <div className='d-flex justify-content-end'>
                                    {/* <div className='rounded-4' style={{ backgroundColor: 'white', marginTop: -50, marginLeft: 20, zIndex: 999 }}>

                                        <Grid padding={1}>
                                            <Typography variant='h4' textAlign="center" color="#1D69E7" className='ff-mi'>26</Typography>
                                            <Typography textAlign="center" fontWeight={'bold'}>Project Done</Typography>
                                        </Grid>
                                    </div> */}
                                    <div className='rounded-circle py-2 p-1' style={{ width: 60, height: 60, backgroundColor: '#F67036', marginTop: -150, marginRight: -30, zIndex: 999 }}>
                                        <img src="assets/icons/Vector_(5).svg" height={48} style={{ marginLeft: '.09em' }} alt="" />
                                    </div>
                                </div>
                                {/* </Animation> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div><div className='bg-secondary bg-opacity-25'>
                <Container maxWidth="lg">
                    <AboutUsWhatWeDo />
                </Container>
            </div><Container maxWidth="lg">
                <AboutUsArialProject />
            </Container><div className='av-bg-primary mt-5'>
                <Container maxWidth="lg">
                    <DashboardGallery />
                </Container>
            </div><AboutUsOurClient />
        </div>
    )
}
