import { Grid, Typography } from '@mui/material'
import React from 'react'

export const ProductPerformance = () => {
    return (
        <div>
            <Grid container display={'flex'} justifyContent={'end'} alignItems={'center'}>
                <Grid container item xs={12} md={5} display={'flex'} justifyContent={'center'}>
                    <Grid item xs={11} md={10} >
                        <Typography className='ff-mi' variant='h4' marginY={2}>Performance you
                            can rely on</Typography>
                        {/* <div className='d-flex justify-content-start' style={{ marginTop: -100 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}
                        <Typography color={'#656565'}>The X8 Hobby Wing Motors: High-performance, reliable, and efficient motors for your drone. Upgrade and enhance your flying experience with excellent thrust and stability. Perfect for aerial photography, racing, and acrobatics. Elevate your drone to new heights with these powerful motors.</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} display={'flex'} justifyContent={'end'}>
                    <img src="assets/drone-left.webp" width={'100%'} alt="" />
                </Grid>
            </Grid>

            <div className='bg-secondary bg-opacity-25 product-bg-img1'>
                <Grid container display={'flex'} justifyContent={'start'} alignItems={'center'}>
                    <Grid item xs={12} md={6} display={'flex'} justifyContent={'start'}>
                        <img src="assets/drone-right.webp" width={'100%'} alt="" />
                    </Grid>
                    <Grid container item xs={12} md={5} display={'flex'} justifyContent={'center'} py={3}>
                        <Grid item xs={11} md={10} >
                            <Typography className='ff-mi' variant='h4' marginY={2}>High-performance battery</Typography>
                            {/* <div className='d-flex justify-content-start' style={{ marginTop: -100 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}
                            <Typography color={'#656565'}>Tattu 16000mAh 22.2V 15/30C 6S1P Lipo Battery Pack with huge capacity for long endurance. It provides stable power with no swelling or overheating at all.</Typography>
                            <Typography color={'#656565'} ml={2} my={2}>Capacity: 16000mAh </Typography>
                            <Typography color={'#656565'} ml={2} my={2}>Voltage: 22.2V </Typography>
                            <Typography color={'#656565'} ml={2} my={2}>Discharge Rate: 15Cv </Typography>
                            <Typography color={'#656565'} ml={2} my={2}>Weight: 1990g </Typography>
                            <Typography color={'#656565'} ml={2} my={2}>Dimensions: 193*77*66 mm</Typography>
                        </Grid>

                    </Grid>

                </Grid>
            </div>
            <div className='av-bg-primary'>
                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid container item xs={12} md={6} display={'flex'} justifyContent={'center'} py={2}>
                        <Grid item xs={11} md={10}>
                            <Typography className='ff-mi' variant='h4' color={'white'} marginY={2}>Survey-grade accuracy</Typography>
                            {/* <div className='d-flex justify-content-start' style={{ marginTop: -100 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}
                            <Typography color={'#DCDCDC'}>Achieve survey-grade accuracy with our advanced drone technology. Our drones are equipped with high-precision sensors and GPS capabilities, ensuring precise and reliable data collection for mapping, surveying, and land analysis. Experience the highest level of accuracy and efficiency in your projects with our survey-grade drones.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5} >
                        <img src="assets/drone-fold.webp" width={'100%'} alt="" />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
