import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export const DashboardGallery = () => {
  return (
    <div className='pb-5'>
      <Grid display={'flex'} justifyContent={'center'} minHeight={150} >
        <Grid >
          {/* <img src="assets/element-blur-2 1.webp" alt="" className='position-absolute z-1' style={{ marginTop: -100, marginLeft: -160 }} /> */}
          <img src="assets/drone_camera.webp" alt="" className='position-absolute z-2' style={{ marginTop: 0, marginLeft: -80 }} />
        </Grid>
      </Grid>
      <Grid marginY={5}>
        <Typography textAlign={'center'} className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>OUR GALLERY</Typography>
        <Typography textAlign={'center'} className='ff-mi' variant='h4' color={'white'} marginY={2}>Unforgettable Memories with PM DRONES</Typography>
        {/* <img src="assets/element-blur-2 1.webp" alt="" className='blur-section-4' /> */}
        <Typography textAlign={'center'} className='ff-mo' color={'white'}>Explore our captivating agriculture drone gallery, showcasing the beauty and efficiency of drone technology in modern farming.</Typography>
      </Grid>
      <Grid container marginTop={5}>
        {[...Array(8)].map((el, i) => (
          <Grid key={'g' + i} padding={1} item xs={12} sm={6} md={4} lg={3}>
            <img src="assets/Frame_10.webp" width={'100%'} alt="" />
          </Grid>
        ))}
      </Grid>
      <Grid display={'flex'} justifyContent={'center'} >
        <Link to={'/gallery'}>
          <button className='btn btn-primary rounded-1 py-2 px-4 ff-mo bg-btn-g shadow' >VIEW GALLERY</button>
        </Link>
      </Grid>
    </div>
  )
}
