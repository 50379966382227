import { Grid, Typography } from '@mui/material'
import React from 'react'

export const DashboardArealProject = () => {
  return (
    <div>
      <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} mt={5}>
        <Grid item xs={12} md={5} px={3}>
          <Typography className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>AERIAL PROJECT</Typography>
          <Typography className='ff-mi' variant='h4' marginY={2}>New Technology and Feature Our Drone</Typography>
          {/* <img src="assets/element-blur-2 1.webp" alt="" className='blur-section-5' /> */}
        </Grid>
        <Grid item xs={12} md={5} sx={{ marginTop: { xs: 2, md: 0 } }}>
          <Typography className='ff-mo' color={'#656565'}>New advanced architecture with power efficient motor and high-performance battery</Typography>
        </Grid>
      </Grid>
      <Grid marginTop={5} container display={'flex'} justifyContent={'center'}>
        <Grid container item xs={12} md={10}>
          <Grid container item xs={12} md={8}>
            <Grid padding={1} item xs={12} md={6}><img src="assets/Frame_4.webp" width={'100%'} alt="" /></Grid>
            <Grid padding={1} item xs={12} md={6}><img src="assets/Frame_5.webp" width={'100%'} alt="" /></Grid>
            <Grid padding={1} item xs={12}><img src="assets/Frame_7.webp" width={'100%'} alt="" /></Grid>
          </Grid>
          <Grid padding={1} item xs={12} md={4}>
            <img src="assets/Frame_6.webp" width={'100%'} alt="" />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
