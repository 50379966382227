import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
// import { Fade } from 'react-reveal'
import React from 'react'
const cards = [
  {
    img: '/assets/card-1.webp',
    title: 'Pesticides Spraying',
    text1: 'Drones minimize the quantity of chemicals used by targeting treatments, reducing the environmental impact.',
    text2: 'Drone spraying is faster and more efficient, covering large areas quickly and allowing farmers to complete spraying tasks in a shorter time.',
    text3: 'Drone spraying reduces human exposure to potentially harmful chemicals, creating a safer working environment.',
    fadeType: 4
  },
  {
    img: '/assets/card-2.webp',
    title: 'Crop Monitoring and Management',
    text1: 'Drones identify early signs of stress, diseases, or nutrient deficiencies in crops, allowing prompt intervention.',
    text2: 'Record and analyze crop damages for accurate insurance settlemenData from drones enable farmers to take corrective actions promptly, addressing specific crop issues..',
    text3: 'Drones help farmers track crop growth and identify any deviations or problem areas.',
    fadeType: 3
  },
  {
    img: '/assets/card-3.webp',
    title: 'Precision Agriculture',
    text1: 'Drones apply fertilizers only where needed, reducing waste and optimizing nutrient use.',
    text2: 'Drones target areas with pests or diseases, minimizing chemical usage.',
    text3: 'Drones optimize water usage based on crop health and moisture data, reducing waste.',
    fadeType: 1
  },
  {
    img: '/assets/card-4.webp',
    title: 'Environmental Monitoring',
    text1: 'Drones analyze soil erosion patterns, aiding in implementing soil conservation measures.',
    text2: 'Drones collect and analyze water samples to assess the impact of farming activities on water quality.',
    text3: 'Drones monitor erosion, water quality, and vegetation, allowing farmers to evaluate their environmental impact.',
    fadeType: 2
  },
];
export const DashboardCard = () => {
  return (
    <Grid container paddingY={5}>
      {cards.map((e) => (
        <Grid key={e.title} item xs={12} sm={6} md={6} lg={3} p={2}>
          {/* <Fade left={e.fadeType === 4} bottom={e.fadeType === 3} right={e.fadeType === 2} top={e.fadeType === 1} cascade> */}
          <div style={{ height: '100%' }}>
            <img src="assets/element-ellipse-1-300x300_1.webp" alt="" style={{ marginLeft: -40, marginBottom: -60, height: 100, width: 100 }} />
            <Card sx={{ borderRadius: 5, height: '100%' }} className='shadow bg-btn-g'>
              <CardActionArea>
                <CardMedia
                  sx={{ position: 'relative' }}
                  component="img"
                  image={e.img}
                  alt="green iguana"
                  width={'100%'}
                />
                <Typography position={'absolute'} width={'100%'} mt={-3} px={3} className='bg-dark bg-opacity-75 fw-lighter ff-mi ts-14' color={'white'}>{e.title}</Typography>
                {/* </CardMedia> */}
                <CardContent>
                  <div className='text-white'>
                    <ul>
                      <li className='my-3 ts-14 ff-mo'>{e.text1}</li>
                      <li className='my-3 ts-14 ff-mo'>{e.text2}</li>
                      <li className='my-3 ts-14 ff-mo'>{e.text3}</li>
                    </ul>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          {/* </Fade> */}
        </Grid>
      ))}
    </Grid>
  )
}
