import { Button, Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { sendSESMail } from '../services/MailService'
import { Link } from 'react-router-dom'
import { Instagram } from '@mui/icons-material'
const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const Footer = () => {
  const [email, setEmail] = useState('')
  const [submit, setSubmit] = useState(false)
  const [isInValidEmail, setIsInValidEmail] = useState(false)
  const sendMail = async () => {
    setSubmit(true)
    const subject = 'Need Aerial Drone'
    const template = `<p>Dear Agrivimaan,<br/> 
    This request coming from <b>"${email}"</b> Plaese Verify</p>`
    if (email === "" || !regex.test(email)) {
      setIsInValidEmail(true)
      return
    } else {
      setIsInValidEmail(false)
    }
    console.log(subject, template)
    await sendSESMail(subject, template);
    setEmail('')
    setSubmit(false);

  }
  const enterEmail = (e) => {
    setEmail(e.target.value)
    if (!regex.test(e.target.value)) {
      setIsInValidEmail(true)
    } else {
      setIsInValidEmail(false)
    }
  }
  const navigateInsta = () => {
    window.open('https://www.instagram.com/agrivimaan/?next=%2F', '_blank').focus();
  }
  const navigateYoutube = () => {
    window.open('https://www.youtube.com/@AgriVimaanSND', '_blank').focus();
  }
  return (
    <div className='av-bg-primary'>
      <Container maxWidth="lg">
        <Grid container justifyContent={'center'}>
          <Grid item xs={0} sm={3} md={4} alignSelf={'center'}><hr className='text-white opacity-1' /></Grid>
          <Grid item xs='auto' sm={6} md={4} justifyContent={'center'} alignSelf={'center'} className='d-flex justify-content-center'>
            <img src="assets/Artboard_3_1.webp" alt='' width={'250px'} /></Grid>
          <Grid item xs={0} sm={3} md={4} alignSelf={'center'} > <hr className='text-white opacity-1' /></Grid>
        </Grid>
        <Grid container >
          <Grid item xs={12} sm={6} md={3}>
            <Typography className='ff-mi' variant='h5' color={'white'}>Pages</Typography>
            <Link className='text-decoration-none' to='/'>
              <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>Home</Typography>
            </Link>
            <Link className='text-decoration-none' to='/gallery'>
              <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>Gallery</Typography>
            </Link>
            <Link className='text-decoration-none' to='/aboutus'>
              <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>About Us</Typography>
            </Link>
            <Link className='text-decoration-none' to='/product'>
              <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>Product-11L Quad</Typography>
            </Link>
            <Link className='text-decoration-none' to='/product-hexa'>
              <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>Product-11L Hexa</Typography>
            </Link>
            <Link className='text-decoration-none' to='/agriculture'>
              <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>Agriculture</Typography>
            </Link>
            <Link className='text-decoration-none' to='/price'>
              <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>Pricing</Typography>
            </Link>
            <Link className='text-decoration-none' to='/contact'>
              <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>Contact Us</Typography>
            </Link>
            <Link className='text-decoration-none' to='/contact-faq'>
              <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>FAQ's</Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography className="ff-mi" variant='h5' color={'white'}>Service</Typography>
            <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>pesticides spraying</Typography>
            <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>fertilizers spraying</Typography>
            <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>agriculture drone sales</Typography>
            <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>agriculture drone service</Typography>
            <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>drone accessories</Typography>
            <Typography className='ff-mo text-capitalize' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>drone pilot training</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography className="ff-mi" variant='h5' color={'white'}>Contact</Typography>
            <Typography className='ff-mo' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>+91 853 546 0665</Typography>
            <Typography className='ff-mo' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>+91 741 137 7836</Typography>
            <Typography className='ff-mo' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>+91 789 999 1769</Typography>
            <Typography className='ff-mo' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>info@agrivimaan.com</Typography>
            <Typography className='ff-mo' sx={{ fontSize: '14px', my: 2, letterSpacing: 1 }} color={'white'}>Sindhanoor, Karnataka</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography className="ff-mi" variant='h5' color={'white'}>Need Aerial Drone?</Typography>
            <p className='ts-14 w-75 text-white mt-2 lsp-1 ff-mo'>Transform farming with
              Agrivimaan's precise and
              efficient drone technology</p>
            <div className='d-flex justify-content-start'>
              <img src='assets/icons/intagram.svg' style={{ cursor: 'pointer' }} alt="" height={40} onClick={navigateInsta} />
              <img src='assets/icons/facebook.svg' style={{ cursor: 'pointer', marginLeft: 15 }} alt="" height={40} />
              <img src='assets/icons/youtube.svg' style={{ cursor: 'pointer', marginLeft: 15 }} alt="" height={40} onClick={navigateYoutube} />
              <img src='assets/icons/linkedin.svg' style={{ cursor: 'pointer', marginLeft: 15 }} alt="" height={40} />
            </div>
            <div className='d-flex w-75 mt-3'>
              <input type="text" value={email} className={`form-control rounded-1 ts-14 ${submit && isInValidEmail ? 'is-invalid error' : ''}`} onChange={enterEmail} placeholder='Your Email here' />
              <button className='rounded-1 btn btn-primary ms-2 ts-14 text-uppercase' onClick={sendMail}>Send</button>
            </div>
          </Grid>
        </Grid>
        <hr className='text-white opacity-1 mt-5' />
        <Grid container maxWidth="lg">
          <Grid item xs={12} sm={6}>
            <Typography className='ff-mo' sx={{ fontSize: '10px', mb: 5, letterSpacing: 1, textAlign: { xs: 'center', sm: 'start' } }} color={'white'}>Copyright©{new Date().getFullYear()} PM Drones</Typography>
          </Grid>
          <Grid item xs={12} sm={6} className='d-flex justify-content-around'>
            <Typography className='ff-mo' sx={{ fontSize: '10px', mb: 5, letterSpacing: 1 }} color={'white'}>Privacy Policy</Typography>
            <Typography className='ff-mo' sx={{ fontSize: '10px', mb: 5, letterSpacing: 1 }} color={'white'}>Term & Services</Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
