import * as React from 'react'
import { makeStyles } from '@mui/styles'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import MenuItem from '@mui/material/MenuItem'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Button from '@mui/material/Button'
import {
    usePopupState,
    bindHover,
    bindFocus,
    bindMenu,
} from 'material-ui-popup-state/hooks'
import { Link, useLocation } from 'react-router-dom'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { blue, red } from '@mui/material/colors'

const useCascadingMenuStyles = makeStyles((theme) => ({
    // submenu: {
    //     marginTop: theme.spacing(-1),
    // },
    // title: {
    //     flexGrow: 1,
    // },
    // moreArrow: {
    //     marginRight: theme.spacing(-1),
    // },
}))

const CascadingContext = React.createContext({
    parentPopupState: null,
    rootPopupState: null,
})

function CascadingMenuItem({ onClick, ...props }) {
    const { rootPopupState } = React.useContext(CascadingContext)
    if (!rootPopupState) throw new Error('must be used inside a CascadingMenu')
    const handleClick = React.useCallback(
        (event) => {
            rootPopupState.close(event)
            if (onClick) onClick(event)
        },
        [rootPopupState, onClick]
    )

    return <MenuItem className='menu-item' {...props} onClick={handleClick} sx={{ width: '180px' }} />
}

function CascadingSubmenu({ title, popupId, ...props }) {
    const classes = useCascadingMenuStyles()
    const { parentPopupState } = React.useContext(CascadingContext)
    const popupState = usePopupState({
        popupId,
        variant: 'popover',
        parentPopupState,
    })
    return (
        <React.Fragment>
            <MenuItem {...bindHover(popupState)} {...bindFocus(popupState)} sx={{ m: 0, p: 0 }}>
                <div style={{ width: '180px', display: 'flex', justifyContent: 'space-between', padding: '6px 15px' }}
                    className='menu-item main-menu'>
                    <span className={classes.title}>{title}</span>
                    <ChevronRight className={classes.moreArrow} />
                </div>
            </MenuItem>
            <CascadingMenu
                {...props}
                classes={{ ...props.classes, paper: classes.submenu }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                popupState={popupState}
            />
        </React.Fragment>
    )
}

function CascadingMenu({ popupState, ...props }) {
    const { rootPopupState } = React.useContext(CascadingContext)
    const context = React.useMemo(
        () => ({
            rootPopupState: rootPopupState || popupState,
            parentPopupState: popupState,
        }),
        [rootPopupState, popupState]
    )

    return (
        <CascadingContext.Provider value={context}>
            <HoverMenu {...props} {...bindMenu(popupState)} />
        </CascadingContext.Provider>
    )
}

const CascadingHoverMenus = ({ menuDetails }) => {
    const popupState = usePopupState({
        popupId: 'demoMenu',
        variant: 'popover',
    })
    const location = useLocation()
    return (
        <div>
            <Button className='text-capitalize'
                {...bindHover(popupState)}
                {...bindFocus(popupState)}
            >
                <Typography noWrap color={location.pathname.includes(menuDetails.colorMatchKey) ? blue[300] : 'white'} textAlign="center">{menuDetails.title}<KeyboardArrowDown /></Typography>
            </Button>
            <CascadingMenu
                popupState={popupState}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {menuDetails.mainMenu.map((e, i) => (
                    e.isHaveSubMenu ?
                        <CascadingSubmenu key={'a' + i}
                            popupId="moreChoicesCascadingMenu"
                            title={e.title}
                        >
                            {e.subMenu.map((el, j) => (
                                el.isHaveSubSubMenu ?
                                    <CascadingSubmenu key={'b' + j}
                                        popupId="evenMoreChoicesCascadingMenu"
                                        title={el.title}
                                    >
                                        {el.subSubMenu.map((elm, k) => (
                                            <Link className='text-decoration-none text-dark' key={'c' + k} to={elm.path}>
                                                <CascadingMenuItem>{elm.title}</CascadingMenuItem>
                                            </Link>
                                        ))}
                                    </CascadingSubmenu>
                                    :
                                    <Link className='text-decoration-none text-dark' key={'d' + j} to={el.path}>
                                        <CascadingMenuItem>{el.title}</CascadingMenuItem>
                                    </Link>
                            ))}
                        </CascadingSubmenu>
                        :
                        <Link className='text-decoration-none text-dark' key={'e' + i} to={e.path}>
                            <CascadingMenuItem>{e.title}</CascadingMenuItem>
                        </Link>
                ))}
            </CascadingMenu>
        </div>
    )
}

export default CascadingHoverMenus