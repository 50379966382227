import { Checkbox, Container, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
// import { Animation } from 'react-animate-style'
import React, { useEffect, useRef, useState } from 'react'
import { sendSESMail } from '../../services/MailService'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'
import { toast } from 'react-toastify'
const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const regexPhone = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/
const options = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}

export const ContactUsContent = ({ refProp }) => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [isInValidPhone, setIsInValidPhone] = useState(false)
    const [isInValidEmail, setIsInValidEmail] = useState(false)
    const [requireType, setRequiredType] = useState('Drone Spray')
    const [permissionCheck, setPermissionCheck] = useState(true)
    const [policyCheck, setPolicyCheck] = useState(true)
    const [submit, setSubmit] = useState(false)


    const SITE_KEY = '6LfKYgwoAAAAALmMXrCRmTpAzkyeLkcgVnfbQ9rZ';
    const SECRET_KEY = '6LfKYgwoAAAAANoGIpMADufkSPQvDaBYSoeU5xSN';
    const captchaRef = useRef(null);
    const sendMail = async () => {
        let token = await captchaRef.current.getValue();
        try {
            let response = await axios(`/recaptcha/api/siteverify?secret=${SECRET_KEY}&response=${token}`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
            setSubmit(true)
            const subject = 'Get in touch'
            if (email === "" || !regexEmail.test(email)) {
                setIsInValidEmail(true)
            } else {
                setIsInValidEmail(false)
            }
            if (phone === "" || !regexPhone.test(phone)) {
                setIsInValidPhone(true)
            } else {
                setIsInValidPhone(false)
            }
            if (name === "" || isInValidEmail || message === "" || isInValidPhone) {
                return
            }
            if (response.data.success) {
                const template = ` <p>Dear Agrivimaan,<br/> 
                This request coming from <b>"${email}"</b> Plaese Verify</p><br/>
                <table border=1>
                <caption style="display: table-caption;caption-side:top;text-align:center">Connect Details</caption>
                <tr><th style="text-align:left;width:50%">Name</th><td>${name}</td></tr>
                <tr><th style="text-align:left;width:50%">Email</th><td>${email}</td></tr>
                <tr><th style="text-align:left;width:50%">Contact Number</th><td>${phone}</td></tr>
                    <tr><th style="text-align:left;width:50%">Message</th><td>${message}</td></tr>
                    <tr><th style="text-align:left;width:50%">Require details for</th><td>
                    ${requireType}</td></tr>
                    <tr><th style="text-align:left;width:50%">AgriVimaan may email and call me with personalized messages and event news.</th><td>${permissionCheck}</td></tr>
                    <tr><th style="text-align:left;width:50%">By providing the required details you acknowledge and provide your express consent that you have read the Privacy Policy as available on our website.</th><td>${policyCheck}</td></tr>
                    </table>`
                console.log(subject, template)
                await sendSESMail(subject, template)
                setSubmit(false)
                setName('')
                setEmail('')
                setPhone('')
                setMessage('')
                setRequiredType('Drone Spray')
                setPermissionCheck(true)
                setPolicyCheck(true)
                await captchaRef.current.reset();
            } else {
                toast.error('Captcha verification required !', options)
            }
        } catch (error) {

        }

    }
    const enterEmail = (e) => {
        setEmail(e.target.value)
        if (!regexEmail.test(e.target.value)) {
            setIsInValidEmail(true)
        } else {
            setIsInValidEmail(false)
        }
    }
    const enterPhone = (e) => {
        setPhone(e.target.value)
        if (!regexPhone.test(e.target.value)) {
            setIsInValidPhone(true)
        } else {
            setIsInValidPhone(false)
        }
    }
    return (
        <div>
            <Container maxWidth='lg'>
                <Grid paddingY={10}>
                    <Typography textAlign={'center'} className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>CONTACT US</Typography>
                    <Grid container display={'flex'} justifyContent={'center'}>
                        <Grid item xs={12} sm={9} md={6}>
                            <Typography textAlign={'center'} className='ff-mi' variant='h4' marginY={2}>AGRIVIMAAN Contact</Typography>
                            {/* <div className='d-flex justify-content-start' style={{ marginTop: -100, marginLeft: 65 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}
                        </Grid>
                    </Grid>
                    <Grid container display={'flex'} justifyContent={'space-around'} mt={5}>
                        <Grid item xs={12} sm={6} md={4} marginY={3}>
                            {/* <Animation className='h-100' animationIn="fadeInLeftBig" animationOut='backInDown' isVisible={true} animationInDelay={1000}> */}
                            <div className='card border-0 bg-secondary bg-opacity-25 p-2 m-3 rounded-4 h-100 shadow'>
                                <div className='d-flex justify-content-between' style={{ marginTop: -50 }}>
                                    <div className='d-flex justify-content-center align-items-center bg-primary rounded-circle ms-3' style={{ height: 80, width: 80 }}>
                                        <img src="assets/icons/Group_(6).svg" alt="" />
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <Typography variant='h6' className='ff-mo' fontWeight={'bold'}>AGRIVIMAAN Email</Typography>
                                    <Typography color={'#656565'} className='ff-mo'>info@agrivimaan.com</Typography>
                                </div>
                            </div>
                            {/* </Animation> */}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} marginY={3} >
                            {/* <Animation className='h-100' animationIn="fadeInLeftBig" animationOut='backInDown' isVisible={true} animationInDelay={500}> */}
                            <div className='card border-0 bg-secondary bg-opacity-25 p-2 m-3 rounded-4 h-100 shadow'>
                                <div className='d-flex justify-content-between' style={{ marginTop: -50 }}>
                                    <div className='d-flex justify-content-center align-items-center bg-primary rounded-circle ms-3' style={{ height: 80, width: 80 }}>
                                        <img src="assets/icons/Group_1.svg" alt="" />
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <Typography variant='h6' className='ff-mo' fontWeight={'bold'}>AGRIVIMAAN Phone</Typography>
                                    <Typography color={'#656565'} className='ff-mo'>+91 853 546 0665, +91 741 137 7836, <br />+91 789 999 1769</Typography>
                                </div>
                            </div>
                            {/* </Animation> */}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} marginY={3}>
                            {/* <Animation className='h-100' animationIn="fadeInLeftBig" animationOut='backInDown' isVisible={true}> */}
                            <div className='card border-0 bg-secondary bg-opacity-25 p-2 m-3 rounded-4 h-100 shadow'>
                                <div className='d-flex justify-content-between' style={{ marginTop: -50 }}>
                                    <div className='d-flex justify-content-center align-items-center bg-primary rounded-circle ms-3' style={{ height: 80, width: 80 }}>
                                        <img src="assets/icons/g2505.svg" alt="" />
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <Typography variant='h6' className='ff-mo' fontWeight={'bold'}>AGRIVIMAAN Office Location</Typography>
                                    <Typography color={'#656565'} className='ff-mo'>Adarsha colony, Sindhanur 584 128</Typography>
                                </div>
                            </div>
                            {/* </Animation> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Grid container ref={refProp} >
                <Grid container item xs={12} md={8} py={3} bgcolor={'#0F2946'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={11} sm={9} lg={6} >
                        <Typography className='ff-mi fw-bold' variant='h6' marginTop={'5'} color={'#1D69E7'}>Let’s Connect</Typography>
                        <Typography className='ff-mi' variant='h4' marginY={2} color={'white'}>Get in touch</Typography>
                        {/* <img src="assets/element-blur-2 1.webp" alt="" className='blur-section-6' /> */}
                        <Typography color={'white'} className='ff-mo ts-14'>
                            Our team of experts will be happy to provide you with detailed information, answer your questions, and discuss how our agri drone technology can benefit your specific needs.
                        </Typography>
                        <div>
                            <div className="mb-3 mt-4">
                                <input type="text" value={name} onChange={(e) => { setName(e.target.value) }} className={`form-control rounded-1 ${submit && !name ? 'is-invalid error' : ''}`} placeholder="Name" />
                            </div>
                            <div className="mb-3">
                                <input type="email" value={email} onChange={enterEmail} className={`form-control rounded-1 ${submit && isInValidEmail ? 'is-invalid error' : ''}`} placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <input type="text" value={phone} onChange={enterPhone} className={`form-control rounded-1 ${submit && isInValidPhone ? 'is-invalid error' : ''}`} placeholder="Phone" />
                            </div>
                            <div className="mb-3">
                                <textarea value={message} onChange={(e) => { setMessage(e.target.value) }} className={`form-control rounded-1 ${submit && !message ? 'is-invalid error' : ''}`} rows="3" placeholder='Message'></textarea>
                            </div>
                            <Grid container display={'flex'} alignItems={'center'} mb={3}>
                                <Grid item xs={12} sm={5}>
                                    <Typography className='text-white text-start'>Require details for*</Typography>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    <select className="form-select" value={requireType} onChange={(e) => { setRequiredType(e.target.value) }}>
                                        <option value="Drone Spray">Drone Spray</option>
                                        <option value="Purchase a Drone">Purchase a Drone</option>
                                        <option value="Become a Drone Partner">Become a Drone Partner</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </Grid>
                            </Grid>
                            {/* <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Grid item xs={12} md={4} >
                                    <Typography className='text-white' sx={{ textAlign: { xs: 'start', md: 'center' } }}>Require details for*</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        defaultValue={'Drone Spray'}
                                        value={requireType}
                                        onChange={(e) => { setRequiredType(e.target.value) }}
                                    >
                                        <FormControlLabel className='text-white' value="Drone Spray" control={<Radio sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'primary',
                                            },
                                        }} />} label="Drone Spray" />
                                        <FormControlLabel className='text-white' value="Purchase a Drone" control={<Radio sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'primary',
                                            },
                                        }} />} label="Purchase a Drone" />
                                        <FormControlLabel className='text-white' value="Become a Drone Partner" control={<Radio sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'primary',
                                            },
                                        }} />} label="Become a Drone Partner" />
                                        <FormControlLabel className='text-white' value="Others" control={<Radio sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'primary',
                                            },
                                        }} />} label="Others" />

                                    </RadioGroup>
                                </Grid>
                            </Grid> */}
                            <FormControlLabel className='text-white' control={<Checkbox value={permissionCheck}
                                sx={{
                                    color: 'white',
                                    '&.Mui-checked': {
                                        color: 'primary',
                                    },
                                }} onChange={(e) => { setPermissionCheck(e.target.value) }} defaultChecked />} label="AgriVimaan may email and call me with personalized messages and event news." />

                            <FormControlLabel className='text-white' control={<Checkbox value={policyCheck}
                                sx={{
                                    color: 'white',
                                    '&.Mui-checked': {
                                        color: 'primary',
                                    },
                                }} onChange={(e) => { setPolicyCheck(e.target.value) }} defaultChecked />} label="By providing the required details you acknowledge and provide your express consent that you have read the Privacy Policy as available on our website." />

                            <div className='d-flex justify-content-center py-2'>
                                <ReCAPTCHA
                                    className="recaptcha"
                                    sitekey={SITE_KEY}
                                    ref={captchaRef}
                                />
                            </div>
                            <div className="my-3">
                                <button className="form-control btn btn-primary rounded-1" onClick={sendMail}>SEND</button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img src="assets/Frame_35.webp" alt="" width={'100%'} height={'100%'} />
                </Grid>
            </Grid>
        </div>
    )
}
