import React from 'react'
import { Routings } from './Routings'
import { Footer } from './layout/Footer'
import './App.css'
import "react-toastify/dist/ReactToastify.css";
import { HeaderNav } from './layout/HeaderNav'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <HeaderNav />
      <Routings />
      <Footer />
    </BrowserRouter>
  )
}

export default App
