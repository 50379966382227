import { Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material'
import YouTube from 'react-youtube';
// import { Fade } from 'react-reveal'
import React from 'react'
import { ProductConnectWithUs } from '../product/ProductConnectWithUs';
import { useState } from 'react';
const cards = [
    {
        img: '/assets/Frame_30.webp',
        text: 'Scaling New Heights in Indian Agriculture: 25,000+ Farms Transformed by Drones',
        fadeType: 4
    },
    {
        img: '/assets/Frame_31.webp',
        text: 'Unveiling Unprecedented Detail: Data Capture at 2mm/pixel Ground Sampling Resolution"',
        fadeType: 3
    },
    {
        img: '/assets/Frame_32.webp',
        text: 'Precise AI/ML Models: Counting Plants, Analyzing Uniformity, and Detecting Tassels with Accuracy',
        fadeType: 2
    },
];
export const DroneExpertiesContent = () => {
    const [playVideo, setPlayVideo] = useState(false)
    return (
        <div>
            <div className='bg-secondary bg-opacity-25'>
                <Container maxWidth='lg' >
                    <Grid container display={'flex'} justifyContent={'center'} pt={5}>
                        <Grid item xs={12} md={6}>
                            <Typography className='ff-mi fw-bold' variant='h6' textAlign={'center'} color={'#1D69E7'}>OUR EXPERTISE</Typography>
                            <Typography textAlign={'center'} className='ff-mi' variant='h4' marginY={2}>Empowering Farmers with Precision Drones</Typography>
                            {/* <div className='d-flex justify-content-start' style={{ marginTop: -150 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}

                        </Grid>
                    </Grid>
                    <Grid container pb={7}>
                        {cards.map((e, i) => (
                            <Grid key={'ex' + i} item xs={12} sm={6} md={4} display={'flex'} justifyContent={'center'} marginY={3}>
                                {/* <Fade left={e.fadeType === 4} bottom={e.fadeType === 3} right={e.fadeType === 2} cascade> */}
                                <Card sx={{ m: 3, borderRadius: 5 }} className='shadow h-100'>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="180"
                                            image={e.img}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography color="#656565" className='ff-mo ts-14'>{e.text}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                {/* </Fade> */}
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
            <div>
                <Container maxWidth={'lg'}>
                    <Grid container display={'flex'} justifyContent={'center'} py={5}>
                        <Grid item xs={12} md={6}>
                            <Typography className='ff-mi fw-bold' variant='h6' textAlign={'center'} color={'#1D69E7'}>HOW WE DELIVER</Typography>
                            <Typography textAlign={'center'} className='ff-mi' variant='h4' marginY={2}>Bringing Precision to Your Fields Delivering Agricultural Excellence with Drones</Typography>
                            {/* <div className='d-flex justify-content-start' style={{ marginTop: -150 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className='bg-secondary bg-opacity-25'>
                <Grid container display={'flex'} alignItems={'center'}>
                    <Grid item xs={12} md={6}>
                        <img src="assets/Frame_33.webp" alt="" width={'100%'} />
                    </Grid>
                    <Grid container item xs={12} md={6} display={'flex'} justifyContent={'start'}>
                        <Grid item xs={12} md={8} ml={2}>
                            <Typography className='ff-mi' variant='h6' marginX={2} marginY={1}>Data Collection</Typography>
                            <Typography className='ff-mo ts-14' marginX={2}>High-resolution imagery and multispectral data capture. Analyze crop health, soil conditions, and more. Inform decisions, boost productivity.</Typography>

                            <Typography className='ff-mi' variant='h6' marginX={2} marginY={1}>Data Processing & Analysis</Typography>
                            <Typography className='ff-mo ts-14' marginX={2}>Agri drones perform data processing and analysis to extract meaningful insights from captured data.</Typography>
                            {/* <Typography className='ff-mo ts-14' marginX={2}> */}
                            <div className='ff-mo ts-14 mx-2'>
                                <ul>
                                    <li>They use advanced algorithms and software to analyze crop health, detect pests or diseases,
                                        measure vegetation indices, and generate maps for precision agriculture.</li>
                                    <li>Identify problem areas, and make data-driven decisions for improved crop management.</li>
                                </ul>
                            </div>
                            {/* </Typography> */}
                            <Typography className='ff-mi' variant='h6' marginX={2} marginY={1}>Data Delivery</Typography>
                            <Typography className='ff-mo ts-14 pb-3' marginX={2}>SkyDeck: Share, Analyze, and Generate High-Precision Field Maps. Collaborate, Annotate, and Gain Insights for Enhanced Farm Management. Simplify Decision-Making with Actionable Reports on Farm Health and Yield Potential.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Container maxWidth="lg">
                <Grid container display={'flex'} justifyContent={'center'} py={5}>
                    <Grid item xs={12} md={6}>
                        <Typography className='ff-mi fw-bold' variant='h6' textAlign={'center'} color={'#1D69E7'}>BENEFITS</Typography>
                        <Typography textAlign={'center'} className='ff-mi' variant='h4' marginY={2}>Embrace the Benefits of Drones for Enhanced Efficiency and Higher Yields</Typography>
                        {/* <div className='d-flex justify-content-start' style={{ marginTop: -140 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}
                    </Grid>
                </Grid>
                <Grid container pb={6}>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className='d-flex justify-content-center align-items-center bg-blur-img'>
                            <img src="assets/icons/Mask_group.svg" alt="" />
                        </div>
                        <Typography textAlign={'center'} className='fw-bold ff-mo ts-15'>Enhanced Efficiency</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className='d-flex justify-content-center align-items-center bg-blur-img'>
                            <img src="assets/icons/Group_10.svg" alt="" />
                        </div>
                        <Typography textAlign={'center'} className='fw-bold ff-mo ts-15'>Precision Farming</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className='d-flex justify-content-center align-items-center bg-blur-img'>
                            <img src="assets/icons/g4044.svg" alt="" />
                        </div>
                        <Typography textAlign={'center'} className='fw-bold ff-mo ts-15'>Time and Cost Savings</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className='d-flex justify-content-center align-items-center bg-blur-img'>
                            <img src="assets/icons/Vector_(6).svg" alt="" />
                        </div>
                        <Typography textAlign={'center'} className='fw-bold ff-mo ts-15'>Elevating Farm Safety</Typography>
                    </Grid>
                </Grid>
            </Container>
            <div className='bg-secondary bg-opacity-25'>
                <Container maxWidth="lg">
                    <Grid container display={'flex'} justifyContent={'center'} py={7}>
                        <Grid item xs={12} md={6}>
                            <Typography className='ff-mi fw-bold' variant='h6' textAlign={'center'} color={'#1D69E7'}>RESOURCES</Typography>
                            <Typography textAlign={'center'} className='ff-mi' variant='h4' marginY={2}>Unlock Accurate Data Insights with our End-to-End Drone Solutions</Typography>
                            {/* <div className='d-flex justify-content-start' style={{ marginTop: -140 }}><img src="assets/element-blur-2 1.webp" alt="" height={150} /></div> */}
                        </Grid>
                    </Grid>
                    <Grid container display={'flex'} justifyContent={'center'} py={7}>
                        <Grid item xs={12} sm={10} md={8}>
                            <div className="ratio ratio-16x9 bg-dark">
                                {playVideo ?
                                    <YouTube videoId='K-k_2k5YejI'
                                        opts={{ width: '100%', height: '100%', playerVars: { autoplay: 1 } }}
                                        onEnd={() => { setPlayVideo(false) }} ></YouTube>
                                    :
                                    <img src="assets/Frame_34.webp" style={{ cursor: 'pointer' }} onClick={() => { setPlayVideo(true) }} alt="" width={'100%'} />
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <ProductConnectWithUs />
        </div>
    )
}
