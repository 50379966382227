import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
const faqs = {
    general: [
        { qn: 'What is the tank capacity ?', ans: '11 liters, filling 8 liters recommended.' },
        { qn: 'How many people will be needed to spray ?', ans: 'Pilot, co-pilot, and helper (arranged by farmer)' },
        { qn: 'Can we spray any type of pesticides ?', ans: 'Yes' },
        { qn: 'Do you train farmers to fly ?', ans: 'Basic training is provided. ' },
        { qn: 'How do we transport it ?', ans: 'Ideally 4-wheeler, you can also modify a 2-wheeler to transport.' },
        { qn: 'How many acres can we spray in a day ?', ans: '(1 acre - 3 minutes) Depending on the spare batteries we can spray 20 - 30 acres in a day' },
        { qn: 'Will we need a generator on the field ?', ans: 'Not needed if there are enough no of spare batteries' },
        { qn: 'What is the name of the company ?', ans: 'Agrivimaan' },
        { qn: 'Are you manufacturing ?', ans: 'We are only assembling in Agrivimaan.' },
        { qn: 'Is it water resistant ?', ans: 'No' },
        { qn: 'How long and high can it fly ?', ans: 'Height locked to 20 meters <br/> Long - can go up to 4 kms.' }
    ],
    battery: [
        { qn: 'What is the flying time of one set of batteries ?', ans: '10 to 15 mins with load.' },
        { qn: 'How many acres (paddy) can we spray with one set of batteries ?', ans: 'Beginner 1 - 2 acres <br/> Intermediate 2 - 3 acres <br/> Expert 3 - 4 acres' },
        { qn: 'What is the charging time ?', ans: '25 minutes' },
        { qn: 'What is the lifespan of the batteries ?', ans: 'Generally 200 - 300 cycles <br/> Well maintained 300 - 400 Cycles' },
        { qn: 'What are the types of batteries available ? Can we use other range batteries ?', ans: 'Yes' },
    ],
    sales: [
        { qn: 'How many sets of batteries do you provide with the purchase of the Drone ?', ans: '1 set ( 2 batteries )' },
        { qn: 'What is the cost of the set of  batteries ?', ans: '1 set ( 2 batteries )  INR 45000 ' },
        { qn: 'Is there a subsidy/Emi Option ?', ans: 'Emi options will be available from 2024' },
        { qn: 'Do we need insurance? Is it available from the company ?', ans: 'Insurance Details will be available form November 2023 ' }
    ],
    maintenance: [
        { qn: 'What is the general maintenance protocol ?', ans: 'Maintenance protocol will be explained during the training program.' },
        { qn: 'Do you provide service and maintenance ?', ans: 'Yes' },
        { qn: 'Do you handle repairs ?', ans: 'Yes' },
        { qn: 'Do you provide services (repairs ) to the location ?', ans: 'Yes, We do provide services (repairs ) to the location' },
        { qn: 'Are spare parts available ?', ans: 'Yes, Available in our Store' }
    ],
    rent: [
        { qn: 'Any discounts on rentals ?', ans: 'It depends on the order.' },
        { qn: 'Do you trail spray ?', ans: 'Based on the request we will do a workshop at your location.' },
        { qn: 'In which locations the rental service is available ?', ans: 'Sindhanur, Maski, Manvi, Raichur, Sirguppa, Gangavati, Hampi, Hospet' },
        { qn: 'Will you need a helper while spraying ?', ans: 'Yes' }

    ]
}

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : '#ffffff',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const ContactUsAccordian = () => {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div className='mt-5'>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography fontWeight={700} className='ff-mi'>General questions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ol className="list-group list-group-numbered list-group-flush border-0">
                        {faqs.general.map((el) => (
                            <li key={el.qn} className="list-group-item d-flex">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{el.qn}</div>
                                    <div dangerouslySetInnerHTML={{ __html: el.ans }}></div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography fontWeight={700} classNameName='ff-mi'>Batteries</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ol className="list-group list-group-numbered list-group-flush border-0">
                        {faqs.battery.map((el) => (
                            <li key={el.qn} className="list-group-item d-flex">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{el.qn}</div>
                                    <div dangerouslySetInnerHTML={{ __html: el.ans }}></div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography fontWeight={700} classNameName='ff-mi'>Sales</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ol className="list-group list-group-numbered list-group-flush border-0">
                        {faqs.sales.map((el) => (
                            <li key={el.qn} className="list-group-item d-flex">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{el.qn}</div>
                                    <div dangerouslySetInnerHTML={{ __html: el.ans }}></div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography fontWeight={700} classNameName='ff-mi'>Maintenance</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ol className="list-group list-group-numbered list-group-flush border-0">
                        {faqs.maintenance.map((el) => (
                            <li key={el.qn} className="list-group-item d-flex">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{el.qn}</div>
                                    <div dangerouslySetInnerHTML={{ __html: el.ans }}></div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography fontWeight={700} classNameName='ff-mi'>Rentals</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ol className="list-group list-group-numbered list-group-flush border-0">
                        {faqs.rent.map((el) => (
                            <li key={el.qn} className="list-group-item d-flex">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{el.qn}</div>
                                    <div dangerouslySetInnerHTML={{ __html: el.ans }}></div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
