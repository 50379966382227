import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
// import { Animation } from 'react-animate-style'
import { ProductPerformance } from '../components/product/ProductPerformance'
import { ProductOperation } from '../components/product/ProductOperation'
import { ProductConnectWithUs } from '../components/product/ProductConnectWithUs'

export const Product = () => {
    return (
        <div>
            <div className='product-bg-img'>
                <Container maxWidth='xl'>
                    <Grid display={'flex'} justifyContent={'end'} minHeight={300} alignItems={'center'}>
                        {/* <Animation animationIn={'fadeInLeftBig'} isVisible={true} > */}
                        <img src="assets/drone-1.webp" alt="" style={{ maxWidth: '100%', maxHeight: '250px' }} />
                        {/* </Animation> */}
                    </Grid>
                </Container>
            </div>
            <div className='product-bg-layer'>
                <Container maxWidth='md'>
                    <Grid pt={2}>
                        {/* <Animation animationIn={'fadeInDownBig'} isVisible={true} animationInDelay={500} className='d-flex justify-content-center' > */}
                        <Typography variant='h4' textAlign={'center'} color={'white'} className='ff-mi'>11L Quad</Typography>
                        {/* </Animation > */}
                        <Grid container display={'flex'} justifyContent={'space-around'} mb={{ xs: 0, md: -5 }} mt={{ xs: 0, md: 5 }}>
                            {/* <Animation animationIn={'fadeInDownBig'} isVisible={true} animationInDelay={700} className='d-flex justify-content-start' > */}
                            <Grid item xs={6} sm={4} md={3} width={'100%'}>
                                <Typography variant='h5' textAlign={'start'} color={'white'} className='ff-mi'>Quadcopter drone
                                    set assembled x1</Typography>
                            </Grid>
                            {/* </Animation > */}
                            {/* <Animation animationIn={'fadeInDownBig'} isVisible={true} animationInDelay={900} className='d-flex justify-content-end' > */}
                            <Grid item xs={6} sm={4} md={3}>
                                <Typography variant='h5' textAlign={'end'} color={'white'} className='ff-mi'>Extended size:
                                    1992*1802*562</Typography>
                            </Grid>
                            {/* </Animation > */}
                        </Grid>
                        {/* <Animation animationIn={'fadeInRight'} isVisible={true} > */}
                        <img src="assets/drone-1.webp" width={'100%'} alt="" />
                        {/* </Animation> */}
                        <Grid container display={'flex'} justifyContent={'space-between'} mt={{ xs: 0, sm: -5, md: -43, lg: -42, xl: -42 }}
                            pb={{ xs: 5, md: 35 }}>
                            {/* <Animation animationIn={'fadeInDownBig'} isVisible={true} animationInDelay={300} className='d-flex justify-content-start' > */}
                            <Grid item xs={6} sm={4} md={3}>
                                <Typography variant='h5' textAlign={'start'} color={'white'} className='ff-mi text-break'>Folding size:
                                    945*848*500mm</Typography>
                            </Grid>
                            {/* </Animation > */}
                            {/* <Animation animationIn={'fadeInDownBig'} isVisible={true} animationInDelay={500} className='d-flex justify-content-end' > */}
                            <Grid item xs={6} sm={4} md={3}>
                                <Typography variant='h5' textAlign={'end'} color={'white'} className='ff-mi'>Motor:
                                    Hobbwying X6 / 6215</Typography>
                            </Grid>
                            {/* </Animation > */}
                        </Grid>

                    </Grid>
                </Container>
            </div>
            <ProductPerformance />
            <ProductOperation />
            <ProductConnectWithUs />
        </div>
    )
}
