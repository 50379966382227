import { Checkbox, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { sendSESMail } from '../../services/MailService'
const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const regexPhone = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/

export const ProductConnectWithUs = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [organization, setOrganization] = useState('')
    const [description, setDescription] = useState('')
    const [requireType, setRequiredType] = useState('Drone Spray')
    const [permissionCheck, setPermissionCheck] = useState(true)
    const [policyCheck, setPolicyCheck] = useState(true)
    const [isInValidEmail, setIsInValidEmail] = useState(false)
    const [isInValidPhone, setIsInValidPhone] = useState(false)
    const [submit, setSubmit] = useState(false)

    const submitForm = async () => {
        setSubmit(true)
        console.log(name, email, phone, organization, description, requireType, permissionCheck, policyCheck)
        if (email === "" || !regexEmail.test(email)) {
            setIsInValidEmail(true)
        } else {
            setIsInValidEmail(false)
        }
        if (phone === "" || !regexPhone.test(phone)) {
            setIsInValidPhone(true)
        } else {
            setIsInValidPhone(false)
        }
        if (isInValidEmail || isInValidPhone || !name || !organization || !description) {
            return
        }
        const subject = 'Connect With Us'
        const template = `
        <p>Dear Agrivimaan,<br/> 
    This request coming from <b>"${email}"</b> Plaese Verify</p><br/>
        <table border=1>
        <caption style="display: table-caption;caption-side:top;text-align:center">Connect Details</caption>
        <tr><th style="text-align:left;width:50%">Name</th><td>${name}</td></tr>
        <tr><th style="text-align:left;width:50%">Email</th><td>${email}</td></tr>
        <tr><th style="text-align:left;width:50%">Contact Number</th><td>${phone}</td></tr>
        <tr><th style="text-align:left;width:50%">Organization Name</th><td>${organization}</td></tr>
        <tr><th style="text-align:left;width:50%">Description</th><td>${description}</td></tr>
        <tr><th style="text-align:left;width:50%">Require details for</th><td>
        ${requireType}</td></tr>
        <tr><th style="text-align:left;width:50%">AgriVimaan may email and call me with personalized messages and event news.</th><td>${permissionCheck}</td></tr>
        <tr><th style="text-align:left;width:50%">By providing the required details you acknowledge and provide your express consent that you have read the Privacy Policy as available on our website.</th><td>${policyCheck}</td></tr>
    </table>`
        await sendSESMail(subject, template);
        setName('')
        setEmail('')
        setPhone('')
        setOrganization('')
        setDescription('')
        setRequiredType('Drone Spray')
        setPermissionCheck(true)
        setPolicyCheck(true)
        setSubmit(false);

    }
    const enterEmail = (e) => {
        setEmail(e.target.value)
        if (!regexEmail.test(e.target.value)) {
            setIsInValidEmail(true)
        } else {
            setIsInValidEmail(false)
        }
    }
    const enterPhone = (e) => {
        setPhone(e.target.value)
        if (!regexPhone.test(e.target.value)) {
            setIsInValidPhone(true)
        } else {
            setIsInValidPhone(false)
        }
    }
    return (
        <div>

            <Container maxWidth="md" className='py-5'>
                <Typography className='ff-mi' variant='h4' textAlign={'center'} marginY={4}>Connect With Us</Typography>
                <Grid container >
                    <Grid item xs={12} md={6} p={2}>
                        <TextField fullWidth label="Name*" value={name} error={submit && !name} onChange={(e) => { setName(e.target.value) }} variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6} p={2}>
                        <TextField fullWidth label="Email*" value={email} error={submit && isInValidEmail} onChange={enterEmail} variant="outlined" />
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid item xs={12} md={6} p={2} >
                        <TextField fullWidth label="Contact Number*" value={phone} type='text' error={submit && isInValidPhone} onChange={enterPhone} variant="outlined" />
                        <TextField sx={{ marginTop: '12px' }} value={organization} error={submit && !organization} onChange={(e) => { setOrganization(e.target.value) }} fullWidth label="Organization Name*" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6} p={2}>
                        <TextField fullWidth label="Description*" value={description} error={submit && !description} onChange={(e) => { setDescription(e.target.value) }} variant="outlined" multiline rows={4} />
                    </Grid>
                </Grid>
                {/* <Grid container display={'flex'} alignItems={'center'}>
                    <Grid item xs={12} md={3} >
                        <Typography className='text-secondary' sx={{ textAlign: { xs: 'start', md: 'center' } }}>Require details for*</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue={'Drone Spray'}
                            value={requireType}
                            onChange={(e) => { setRequiredType(e.target.value) }}
                        >
                            <FormControlLabel className='text-secondary' value="Drone Spray" control={<Radio />} label="Drone Spray" />
                            <FormControlLabel className='text-secondary' value="Purchase a Drone" control={<Radio />} label="Purchase a Drone" />
                            <FormControlLabel className='text-secondary' value="Become a Drone Partner" control={<Radio />} label="Become a Drone Partner" />
                            <FormControlLabel className='text-secondary' value="Others" control={<Radio />} label="Others" />

                        </RadioGroup>
                    </Grid>
                </Grid> */}
                <Grid container p={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Require details for*</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={requireType}
                            label="Require details for*"
                            onChange={(e) => { setRequiredType(e.target.value) }}
                        >
                            <MenuItem value={'Drone Spray'}>Drone Spray</MenuItem>
                            <MenuItem value={'Purchase a Drone'}>Purchase a Drone</MenuItem>
                            <MenuItem value={'Become a Drone Partner'}>Become a Drone Partner</MenuItem>
                            <MenuItem value={'Others'}>Others</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container display={'flex'} justifyContent={'center'}>
                    <Grid item xs={12} md={10} >
                        <FormControlLabel className='text-secondary' control={<Checkbox value={permissionCheck} onChange={(e) => { setPermissionCheck(e.target.value) }} defaultChecked />} label="AgriVimaan may email and call me with personalized messages and event news." />
                    </Grid>
                </Grid>
                <Grid container display={'flex'} justifyContent={'center'}>
                    <Grid item xs={12} md={10} >
                        <FormControlLabel className='text-secondary' control={<Checkbox value={policyCheck} onChange={(e) => { setPolicyCheck(e.target.value) }} defaultChecked />} label="By providing the required details you acknowledge and provide your express consent that you have read the Privacy Policy as available on our website." />
                    </Grid>
                </Grid>
                <Grid mt={3} display={'flex'} justifyContent={'center'} >
                    <button className='btn btn-primary rounded-1 py-2 px-4 ff-mo bg-btn-g shadow' onClick={submitForm} >SUBMIT</button>
                </Grid>
            </Container>
        </div>
    )
}
